const window = require('window')

const darkMode = window.location.hostname.startsWith('dark')
window.ga =
  window.ga ||
  function() {
    ;(window.ga.q = window.ga.q || []).push(arguments)
  }
window.ga.l = +new Date()
if (darkMode) window.ga('create', GA_KEY_DARK, 'auto')
else window.ga('create', GA_KEY, 'auto')

module.exports = window.ga
