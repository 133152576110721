// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/pages/about.marko", function() {
      return module.exports;
    }),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    module_Surround = require("MarkoSurround"),
    Surround = module_Surround.default || module_Surround,
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_dynamicTag = marko_helpers.d,
    marko_attrs0 = {
        "class": "row"
      },
    marko_attrs1 = {
        "class": "col s12"
      },
    marko_createElement = marko_helpers.e,
    marko_const = marko_helpers.const,
    marko_const_nextId = marko_const("91e480"),
    marko_node0 = marko_createElement("h1", null, "4", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Om Kostbevakningen"),
    marko_node1 = marko_createElement("h2", null, "5", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Debug"),
    marko_node2 = marko_createElement("h3", null, "6", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Version"),
    marko_node3 = marko_createElement("h3", null, "8", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Build");

function render(input, out, __component, component, state) {
  var data = input;

  marko_dynamicTag(out, Surround, function() {
    return {
        content: {
            renderBody: function(out) {
              out.e("div", marko_attrs0, "2", component, 1)
                .e("div", marko_attrs1, "3", component, 6)
                  .n(marko_node0, component)
                  .n(marko_node1, component)
                  .n(marko_node2, component)
                  .e("p", null, "7", component, 1)
                    .t(VERSION)
                  .n(marko_node3, component)
                  .e("p", null, "9", component, 1)
                    .t(COMMITHASH);
            }
          }
      };
  }, null, null, null, __component, "0");
}

marko_template._ = marko_renderer(render, {
    ___implicit: true,
    ___type: marko_componentType
  });

marko_template.Component = marko_defineComponent({}, marko_template._);
