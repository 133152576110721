import fetchPonyfill from 'fetch-ponyfill'
const { fetch } = fetchPonyfill()
import { default as logger } from './logger.js'

export const post = (uri, json) => doit('POST', uri, json)
export const get = uri => doit('GET', uri)
export const del = uri => doit('DELETE', uri)

function doit(method, uri, json) {
  const postOpts = {
    method,
    body: json ? JSON.stringify(json) : undefined,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const opts = Object.assign({}, postOpts)

  return fetch(uri, opts)
    .then(response => {
      logger.debug('Got a http response', response)
      return response
    })
    .then(response => response.json())
}

export const getXml = uri => {
  return fetch(uri, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/xml'
    }
  })
}
