const M = require('M')

module.exports = {
  initDropdown() {
    const el = this.getEl('trigger')
    M.Dropdown.init(el, { coverTrigger: false })
  },

  onMount() {
    this.initDropdown()
  },
}
