import { get, post, del } from '@kb-front/common/fetch'

export const textToRows = text => text.split('\n').map(r => r.trim())

export const calculateRows = rows => {
  const request = {
    apiKey: 'ePACZdXq2WSmgjuF',
    text: rows.join('\n'),
  }

  return post(`${API_URL}/calculate`, request)
}

export const saveEntry = entry => post('/api/food-diary/', entry)
export const deleteEntry = id => del(`/api/food-diary/${id}`)
export const getEntries = () => get('/api/food-diary/')
export const searchGraph = text => get(`/api/food/search/${text}`)
