// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/pages/diary/components/diary-table.marko", function() {
      return module.exports;
    }),
    marko_component = require("./diary-table.component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    module_formatting_module = require("@kb-front/common/formatting"),
    formatting_module = module_formatting_module.default || module_formatting_module,
    formatGrams = module_formatting_module.formatGrams,
    formatKcal = module_formatting_module.formatKcal,
    formatPercent = module_formatting_module.formatPercent,
    module_logger_module = require("@kb-front/common/logger"),
    logger_module = module_logger_module.default || module_logger_module,
    debug = module_logger_module.debug,
    module_moment = require("moment"),
    moment = module_moment.default || module_moment,
    marko_forEachProp = require("marko/src/runtime/helper-forEachProperty"),
    nutrient_bars_template = require("../../../components/nutrient-bars.marko"),
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_loadTag = marko_helpers.t,
    nutrient_bars_tag = marko_loadTag(nutrient_bars_template),
    marko_forEach = marko_helpers.f,
    edit_diary_entry_template = require("./edit-diary-entry.marko"),
    edit_diary_entry_tag = marko_loadTag(edit_diary_entry_template),
    collapsible_template = require("./collapsible.marko"),
    collapsible_tag = marko_loadTag(collapsible_template),
    marko_attrs0 = {
        "class": "date"
      },
    marko_attrs1 = {
        "class": "closed"
      },
    marko_attrs2 = {
        colspan: "9"
      },
    marko_attrs3 = {
        "class": "row"
      },
    marko_attrs4 = {
        "class": "col s12"
      };

function render(input, out, __component, component, state) {
  var data = input;

  var $for$0 = 0;

  marko_forEachProp(state.entries, function(dateString, {entries, totals, expanded}) {
    const day = moment(dateString)

    var $keyScope$0 = "[" + (($for$0++) + "]");

    out.e("h2", null, "0" + $keyScope$0, component, 2)
      .t(day.format("dddd"))
      .e("span", marko_attrs0, "1" + $keyScope$0, component, 1)
        .t(day.format("YYYY-MM-DD"));

    out.be("table", null, "2" + $keyScope$0, component);

    out.be("thead", null, "3" + $keyScope$0, component);

    out.be("tr", null, "4" + $keyScope$0, component);

    out.be("th", null, "5" + $keyScope$0, component);

    nutrient_bars_tag({
        currentRow: totals,
        maxNutrient: state.maxKjOfAnyNutr
      }, out, __component, "6" + $keyScope$0);

    out.ee();

    out.e("th", null, "7" + $keyScope$0, component, 1)
      .t("Totalt");

    out.be("th", null, "8" + $keyScope$0, component);

    out.h(formatKcal(totals.kcalTotal));

    out.ee();

    out.be("th", null, "9" + $keyScope$0, component);

    out.h(formatGrams(totals.grams.protein));

    out.t(" protein");

    out.ee();

    out.be("th", null, "10" + $keyScope$0, component);

    out.h(formatGrams(totals.grams.fat));

    out.t(" fett");

    out.ee();

    out.be("th", null, "11" + $keyScope$0, component);

    out.h(formatGrams(totals.grams.carbs));

    out.t(" kolh.");

    out.ee();

    out.ee();

    out.ee();

    out.be("tbody", null, "12" + $keyScope$0, component);

    var $for$1 = 0;

    marko_forEach(entries, function(entry) {
      var $keyScope$1 = "[" + ((($for$1++) + $keyScope$0) + "]");

      out.be("tr", marko_attrs1, "13" + $keyScope$1, component, null, 0, {
          onclick: __component.d("click", "toggle", false, [
              entry.id
            ])
        });

      out.be("td", null, "14" + $keyScope$1, component);

      nutrient_bars_tag({
          currentRow: entry.result.totals,
          maxNutrient: state.maxKjOfAnyNutr
        }, out, __component, "15" + $keyScope$1);

      out.ee();

      out.e("td", null, "16" + $keyScope$1, component, 3)
        .t(entry.title)
        .t(" ")
        .t(entry.time);

      out.be("td", null, "17" + $keyScope$1, component);

      out.h(formatKcal(entry.result.totals.kcal.total));

      out.ee();

      out.be("td", null, "18" + $keyScope$1, component);

      out.h(formatGrams(entry.result.totals.grams.protein));

      out.t(" protein");

      out.ee();

      out.be("td", null, "19" + $keyScope$1, component);

      out.h(formatGrams(entry.result.totals.grams.fat));

      out.t(" fett");

      out.ee();

      out.be("td", null, "20" + $keyScope$1, component);

      out.h(formatGrams(entry.result.totals.grams.carbs));

      out.t(" kolh.");

      out.ee();

      out.ee();

      collapsible_tag({
          animateOn: (() => component.getEl(`animated`)),
          renderBody: function(out) {
            out.be("tr", null, "21" + $keyScope$1, component);

            out.be("td", marko_attrs2, "22" + $keyScope$1, component);

            out.be("div", null, "@animated", component);

            edit_diary_entry_tag({
                diary: entry
              }, out, __component, "23" + $keyScope$1, [
              [
                "saved-entry",
                "savedEntry",
                false
              ],
              [
                "deleted-entry",
                "deletedEntry",
                false
              ],
              [
                "abort",
                "toggle",
                false,
                [
                    entry.id
                  ]
              ]
            ]);

            out.ee();

            out.ee();

            out.ee();
          }
        }, out, __component, entry.id);
    });

    out.ee();

    out.ee();

    if (!state.newEntries[dateString]) {
      out.e("a", null, "24" + $keyScope$0, component, 1, 0, {
          onclick: __component.d("click", "add", false, [
              dateString
            ])
        })
        .t("+ Lägg till måltid");
    }

    collapsible_tag({
        renderBody: function(out) {
          out.be("div", marko_attrs3, "25" + $keyScope$0, component);

          out.be("div", marko_attrs4, "26" + $keyScope$0, component);

          if (state.newEntries[dateString]) {
            edit_diary_entry_tag({
                diary: state.newEntries[dateString]
              }, out, __component, "27" + $keyScope$0, [
              [
                "abort",
                "abort",
                false,
                [
                    dateString
                  ]
              ]
            ]);
          }

          out.ee();

          out.ee();
        }
      }, out, __component, dateString);
  });
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
