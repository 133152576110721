import { document } from 'window'

export const seDate = new Intl.DateTimeFormat('sv-SE')
export const seTime = new Intl.DateTimeFormat('sv-SE', {
  hour: 'numeric',
  minute: 'numeric',
})

const Mi18nSE = {
  i18n: {
    cancel: 'Avbryt',
    done: 'Ok',
    clear: 'Rensa',
    months: [
      'januari',
      'februari',
      'mars',
      'april',
      'maj',
      'juni',
      'juli',
      'augusti',
      'september',
      'oktober',
      'november',
      'december',
    ],
    monthsShort: ['jan', 'feb', 'mars', 'april', 'maj', 'juni', 'juli', 'aug', 'sept', 'okt', 'nov', 'dec'],
    weekdays: ['söndag', 'måndag', 'tisdag', 'onsdag', 'torsdag', 'fredag', 'lördag'],
    weekdaysShort: ['mån', 'tis', 'ons', 'tors', 'fre', 'lör', 'sön'],
    weekdaysAbbrev: ['S', 'M', 'T', 'O', 'T', 'F', 'L'],
  },
}

export const datepickerOptions = {
  format: 'yyyy-mm-dd',
  container: document.body,
  i18n: Mi18nSE.i18n,
  firstDay: 1, // monday first day
  defaultDate: Date.now(),
  setDefaultDate: true,
}

export const timepickerOptions = {
  twelveHour: false,
  format: 'HH:mm',
}
