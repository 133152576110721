const { isActive, navigate } = require('marko-router5/src/index')
const window = require('window')

module.exports = class {
  onMount() {
    if (window.router) {
      window.router.useMiddleware(() => (toState, fromState, done) => this.middleActive(toState, fromState, done))
    }
    if (!this.pass) this.middleActive({ path: window.location.pathname })
  }

  navigate(evt) {
    const comboKey = evt.metaKey || evt.altKey || evt.ctrlKey || evt.shiftKey

    if (evt.button === 0 && !comboKey) {
      evt.preventDefault()
      if (this.input.href) navigate(this.input.href)
      else throw new Error("Missing attribute 'href' on kb-link component") //eslint-disable-line
    }
  }

  middleActive(toState, fromState, done) {
    this.pass = true
    const a = this.input.parentClass ? this.getEl('a').parentElement : this.getEl('a')
    const className = this.input.activeClass || 'active'
    if (isActive(this.input.href, toState)) {
      className.split(' ').forEach(c => a.classList.add(c))
    } else {
      className.split(' ').forEach(c => a.classList.remove(c))
    }
    if (done) done()
  }
}
