const M = require('M')

module.exports = {
  onMount() {
    const el = this.getEl()
    this.sidenav = M.Sidenav.init(el)
  },

  toggleNavbar() {
    if (this.sidenav) {
      if (this.sidenav.isOpen) {
        this.sidenav.close()
      } else {
        this.sidenav.open()
      }
    }
  },
}
