const { calculateRows, saveEntry } = require('Services/food-api')

const cacheService = require('Services/cache').default

const { cache, cached } = cacheService('kb-diary')

const { history: History } = require('window')
const logger = require('@kb-front/common/logger')

function textToRows(text) {
  return text.split('\n').map(r => r.trim())
}

module.exports = {
  onCreate(input) {
    this.state = {
      result: {},
      hasResult: false,
    }

    let textOnLoad = input.params.foodinput ? input.params.foodinput.replace(/\+/g, ' ') : undefined

    if (textOnLoad) {
      cache(textOnLoad)
    } else {
      textOnLoad = cached()
      if (textOnLoad) this.updateHistory(textOnLoad)
    }

    if (textOnLoad) {
      this.textOnLoad = textOnLoad
      this.recalc(textOnLoad)
    } else {
      this.setState('hasResult', false)
    }
  },

  updateHistory(text) {
    History.replaceState(null, '', text.length ? '?foodinput=' + encodeURIComponent(text) : '/')
  },

  foodInputChanged(text) {
    this.updateHistory(text)
    cache(text)
    this.recalc(text)
  },

  recalc(text) {
    calculateRows(textToRows(text)).then(result => {
      this.setState('result', result)
      this.setState('hasResult', result && result.rows && result.rows.length > 0)
    })
  },

  add(metadata) {
    const entry = Object.assign({}, metadata, { input: cached(), result: this.state.result })
    logger.debug('to save', entry)
    saveEntry(entry)
    this.getComponent('diary-table').refreshEntries()
  },
}
