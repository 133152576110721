// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/materialize-wrappers/modal/index.marko", function() {
      return module.exports;
    }),
    marko_component = require("./component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_dynamicTag = marko_helpers.d,
    marko_classAttr = marko_helpers.ca,
    marko_attrs0 = {
        "class": "modal-content"
      },
    marko_attrs1 = {
        "class": "modal-footer"
      };

function render(input, out, __component, component, state) {
  var data = input;

  out.be("div", {
      "class": marko_classAttr({
          modal: true,
          "modal-fixed-footer": input.fixedFooter
        })
    }, "0", component, null, 1);

  out.be("div", marko_attrs0, "1", component);

  marko_dynamicTag(out, input.content, null, null, null, null, __component, "2");

  out.ee();

  out.be("div", marko_attrs1, "3", component);

  marko_dynamicTag(out, input.footer, null, null, null, null, __component, "4");

  out.ee();

  out.ee();
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
