// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/pages/diary/components/edit-diary-entry.marko", function() {
      return module.exports;
    }),
    marko_component = require("./edit-diary-entry.component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    module_formatting_module = require("@kb-front/common/formatting"),
    formatting_module = module_formatting_module.default || module_formatting_module,
    formatGrams = module_formatting_module.formatGrams,
    formatKcal = module_formatting_module.formatKcal,
    formatPercent = module_formatting_module.formatPercent,
    food_input_template = require("../../../components/food-input/index.marko"),
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_loadTag = marko_helpers.t,
    food_input_tag = marko_loadTag(food_input_template),
    food_output_template = require("../../../components/food-output/index.marko"),
    food_output_tag = marko_loadTag(food_output_template),
    marko_attrs0 = {
        "class": "row"
      },
    marko_attrs1 = {
        "class": "row"
      },
    marko_attrs2 = {
        "class": "row"
      },
    marko_attrs3 = {
        "class": "col m6 s12 food-input"
      },
    marko_attrs4 = {
        "class": "action-buttons"
      },
    marko_attrs5 = {
        "class": "btn right btn-flat waves-effect waves-light"
      },
    marko_attrs6 = {
        "class": "col m6 s12"
      },
    marko_attrs7 = {
        "class": "input-field col m6 s12"
      },
    marko_attrs8 = {
        "class": "input-field col m3 s6"
      },
    marko_attrs9 = {
        "class": "input-field col m3 s6"
      },
    marko_attrs10 = {
        "class": "btn waves-effect waves-light"
      },
    marko_attrs11 = {
        "class": "btn right btn-flat waves-effect waves-light"
      };

function render(input, out, __component, component, state) {
  var data = input;

  const entry = input.diary

  if (entry) {
    out.e("div", marko_attrs0, "0", component, 1)
      .e("div", marko_attrs7, "1", component, 2)
        .e("input", {
            value: state.title,
            id: __component.elId("title")
          }, "@title", component, 0, 0, {
            onchange: __component.d("change", "updateField", false, [
                "title"
              ])
          })
        .e("label", {
            "class": "active",
            "for": __component.elId("title")
          }, "2", component, 1)
          .t("Måltid");

    out.e("div", marko_attrs1, "3", component, 2)
      .e("div", marko_attrs8, "4", component, 2)
        .e("input", {
            value: state.dateString,
            id: __component.elId("date")
          }, "@date-input", component, 0, 0, {
            onchange: __component.d("change", "updateField", false, [
                "dateString"
              ])
          })
        .e("label", {
            "class": "active",
            "for": __component.elId("date")
          }, "5", component, 1)
          .t("Datum")
      .e("div", marko_attrs9, "6", component, 2)
        .e("input", {
            value: state.timeString,
            id: __component.elId("time")
          }, "@time-input", component, 0, 0, {
            onchange: __component.d("change", "updateField", false, [
                "timeString"
              ])
          })
        .e("label", {
            "class": "active",
            "for": __component.elId("time")
          }, "7", component, 1)
          .t("Tid");

    out.be("div", marko_attrs2, "8", component);

    out.be("div", marko_attrs3, "9", component);

    food_input_tag({
        hideButtons: true,
        text: entry.input
      }, out, __component, "10", [
      [
        "food-input-changed",
        "recalc",
        false
      ]
    ]);

    out.be("div", marko_attrs4, "11", component);

    const saveText = entry.id ? 'Spara ändringar' : 'Spara' 

    if (entry.result) {
      out.e("button", marko_attrs10, "12", component, 1, 0, {
          onclick: __component.d("click", "save", false)
        })
        .t(saveText);
    }

    if (entry.id) {
      out.e("button", marko_attrs11, "13", component, 1, 0, {
          onclick: __component.d("click", "delete", false)
        })
        .t("Radera");
    }

    out.e("button", marko_attrs5, "14", component, 1, 0, {
        onclick: __component.d("click", "abort", false)
      })
      .t("Avbryt");

    out.ee();

    out.ee();

    out.be("div", marko_attrs6, "15", component);

    food_output_tag({
        response: state.result
      }, out, __component, "food-output");

    out.ee();

    out.ee();
  }
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
