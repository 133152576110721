const window = require('window')

module.exports = {
  onCreate() {
    this.state = { width: window.innerWidth }
  },

  onMount() {
    window.addEventListener('resize', () => (this.state = { width: window.innerWidth }))
  },

  onInput(input) {
    this.state = {
      response: input.response,
      width: window.innerWidth,
    }
  },
}
