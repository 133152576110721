// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/surround.marko", function() {
      return module.exports;
    }),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    kb_header_template = require("./components/kb-header/kb-header.marko"),
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_loadTag = marko_helpers.t,
    kb_header_tag = marko_loadTag(kb_header_template),
    marko_dynamicTag = marko_helpers.d,
    terms_modal_template = require("./components/terms-modal.marko"),
    terms_modal_tag = marko_loadTag(terms_modal_template),
    kb_footer_template = require("./components/kb-footer.marko"),
    kb_footer_tag = marko_loadTag(kb_footer_template),
    marko_createElement = marko_helpers.e,
    marko_const = marko_helpers.const,
    marko_const_nextId = marko_const("73da4f"),
    marko_node0 = marko_createElement("div", {
        id: "fb-root"
      }, "1", null, 0, 0, {
        i: marko_const_nextId()
      }),
    marko_node1 = marko_createElement("script", null, "2", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("(function(d, s, id) {\n  var js, fjs = d.getElementsByTagName(s)[0];\n  if (d.getElementById(id)) return;\n  js = d.createElement(s); js.id = id;\n  js.src = 'https://connect.facebook.net/sv_SE/sdk.js#xfbml=1&version=v3.1&appId=336637486418624&autoLogAppEvents=1';\n  fjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));"),
    marko_attrs0 = {
        role: "main"
      },
    marko_attrs1 = {
        id: "wrapper"
      },
    marko_attrs2 = {
        "class": "container"
      };

function render(input, out, __component, component, state) {
  var data = input;

  kb_header_tag({}, out, __component, "0");

  out.n(marko_node0, component);

  out.n(marko_node1, component);

  out.be("main", marko_attrs0, "3", component);

  out.be("div", marko_attrs1, "4", component);

  out.be("div", marko_attrs2, "5", component);

  marko_dynamicTag(out, input.content, null, null, null, null, __component, "6");

  out.ee();

  out.ee();

  out.ee();

  terms_modal_tag({}, out, __component, "7");

  kb_footer_tag({}, out, __component, "8");
}

marko_template._ = marko_renderer(render, {
    ___implicit: true,
    ___type: marko_componentType
  });

marko_template.Component = marko_defineComponent({}, marko_template._);
