const FBPromise = require('Services/facebook')
const logger = require('@kb-front/common/logger').default

module.exports = {
  onCreate() {
    logger.debug('fb-login-modal created')
    this.on('open', () => {
      logger.debug('fb-login-modal on-open')
      this.getComponent('modal').emit('open')
    })
  },

  onMount() {
    const component = this
    FBPromise.then(FB => {
      logger.debug('onMount in fb-login-modal with FB being', FB)
      FB.XFBML.parse(component.getEl('modal-inner'))

      // Close popup upon login
      FB.Event.subscribe('auth.authResponseChange', () => {
        component.close()
      })
    }).catch(err => {
      logger.error(err)
    })
  },

  close() {
    this.getComponent('modal').emit('close')
  },
}
