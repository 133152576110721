const M = require('M')
const ga = require('googleanalytics')

function delay(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

function createNewTimer(f) {
  let cancelled = false
  delay(1500).then(() => {
    if (!cancelled) f()
  })
  return {
    kill: () => (cancelled = true),
  }
}

module.exports = {
  onMount() {
    const textarea = this.getEl('textarea')
    M.textareaAutoResize(textarea)
    textarea.focus()
  },

  textUpdated(event, el) {
    const text = el.value
    if (event.inputType === 'insertLineBreak') {
      ga('send', 'event', 'Calculate', 'NewLine', text)
      this.emit('text-updated', text)
    } else {
      if (this.currentTimer) this.currentTimer.kill()
      this.currentTimer = createNewTimer(() => {
        ga('send', 'event', 'Calculate', 'Typing', text)
        this.emit('text-updated', text)
      })
    }
  },

  reset() {
    const textArea = this.getEl('textarea')

    // Reset content
    textArea.value = ''
    this.emit('text-updated', '')

    // Tell Materialize to autoresize.
    M.textareaAutoResize(textArea)

    // Request focus
    textArea.focus()
  },

  getText() {
    return this.getEl('textarea').value
  },
}
