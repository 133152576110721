// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    marko_component = {
    onCreate: function (input) {
        this.state = { diaryEntry: undefined };
    },
    createNew: function () {
        this.state.diaryEntry = newEntry();
        this.getComponent('new-meal').toggle().then(() => {
        });
    },
    abort: function () {
        this.getComponent('new-meal').toggle().then(() => {
            this.state.diaryEntry = undefined;
        });
    }
},
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/pages/diary/components/add-meal-top.marko", function() {
      return module.exports;
    }),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    module_diary_module = require("Services/diary"),
    diary_module = module_diary_module.default || module_diary_module,
    newEntry = module_diary_module.newEntry,
    edit_diary_entry_template = require("./edit-diary-entry.marko"),
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_loadTag = marko_helpers.t,
    edit_diary_entry_tag = marko_loadTag(edit_diary_entry_template),
    collapsible_template = require("./collapsible.marko"),
    collapsible_tag = marko_loadTag(collapsible_template),
    marko_attrs0 = {
        "class": "btn waves-effect waves-light"
      };

function render(input, out, __component, component, state) {
  var data = input;

  collapsible_tag({
      renderBody: function(out) {
        out.be("div", null, "0", component);

        if (state.diaryEntry) {
          edit_diary_entry_tag({
              diary: state.diaryEntry
            }, out, __component, "1", [
            [
              "abort",
              "abort",
              false
            ]
          ]);
        }

        out.ee();
      }
    }, out, __component, "new-meal");

  if (!state.diaryEntry) {
    out.e("button", marko_attrs0, "@button", component, 1, 0, {
        onclick: __component.d("click", "createNew", false)
      })
      .t("+ Lägg till måltid");
  }
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
