const { anime } = require('M')
const window = require('window')

const hide = el => {
  const s = el.style
  s.display = 'none'
}

const show = el => {
  const s = el.style
  s.display = ''
}

const preScrollIn = el => {
  const s = el.style
  s.display = ''
  s.overflow = 'hidden'
  s.height = 0
  s.paddingTop = ''
  s.paddingBottom = ''
}

const defaults = {
  accordion: true,
  onOpenStart: undefined,
  onOpenEnd: undefined,
  onCloseStart: undefined,
  onCloseEnd: undefined,
  inDuration: 300,
  outDuration: 300,
}

const expand = el =>
  new Promise((resolve, reject) => {
    anime.remove(el)

    preScrollIn(el)

    const c = window.getComputedStyle(el, null)
    const pTop = c.getPropertyValue('padding-top')
    const pBottom = c.getPropertyValue('padding-bottom')
    const finalHeight = el.scrollHeight

    const s = el.style
    s.paddingTop = 0
    s.paddingBottom = 0

    anime({
      targets: el,
      height: finalHeight,
      paddingTop: pTop,
      paddingBottom: pBottom,
      duration: defaults.inDuration,
      easing: 'easeInOutCubic',
      complete: () => {
        s.overflow = ''
        s.height = ''
        s.paddingTop = ''
        s.paddingBottom = ''
        s.display = ''
        resolve()
      },
    })
  })

const collapse = el =>
  new Promise(resolve => {
    anime.remove(el)

    const s = el.style
    s.overflow = 'hidden'
    anime({
      targets: el,
      height: 0,
      paddingTop: 0,
      paddingBottom: 0,
      duration: defaults.outDuration,
      easing: 'easeInOutCubic',
      complete: () => {
        s.overflow = ''
        s.height = ''
        s.padding = ''
        s.display = 'none'
        resolve()
      },
    })
  })

module.exports = {
  onCreate(input) {
    this.state = {
      expanded: input.expanded || false,
    }
  },
  getAnimatedEl() {
    return this.input.animateOn ? this.input.animateOn() : this.getEl()
  },

  onMount() {
    hide(this.getEl())
  },
  toggle() {
    let p
    if (!this.state.expanded) {
      show(this.getEl())
      p = expand(this.getAnimatedEl())
    } else {
      p = collapse(this.getAnimatedEl()).then(() => {
        hide(this.getEl())
      })
    }
    return p.then(() => {
      this.state.expanded = !this.state.expanded
    })
  },
}
