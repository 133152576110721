const ga = require('googleanalytics')
const window = require('window')
require('Services/facebook') // just to speed up
require('hotjar')

const logRoute = () => {
  setTimeout(() => {
    ga('set', 'page', window.location.pathname)
    ga('set', 'location', window.location.origin + window.location.pathname)
    ga('send', 'pageview')
  }, 800)
}
module.exports = {
  onMount() {
    logRoute()
  },
  changedPage() {
    logRoute()
  },
}
