const window = require('window')
const { document } = window

/**
 * Gives a promise of the FB Api.
 *
 * The FB api is fetched by a typical script tag that in turn loads FB's code and also calls
 * a function fbAsyncInit function on window. So I resolve the API promise in there.
 */
module.exports = new Promise(resolve => {
  // Magic name that's called by FB when loaded
  window.fbAsyncInit = function() {
    FB.init({ // eslint-disable-line
      appId: FB_CLIENT_ID,
      autoLogAppEvents: true, // tracking
      xfbml: true,
      version: 'v3.3',
    })
    resolve(FB) // eslint-disable-line
  }

  // Load the SDK asynchronously
  ;(function(d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) return
    js = d.createElement(s)
    js.id = id
    js.src = FACEBOOK_SDK_URL
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'facebook-jssdk')
})
