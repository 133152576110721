// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/components/smart-textarea/index.marko", function() {
      return module.exports;
    }),
    marko_component = require("./component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c;

function render(input, out, __component, component, state) {
  var data = input;

  out.e("textarea", {
      value: input.text,
      autofocus: true,
      "data-hj-whitelist": true,
      "class": "materialize-textarea",
      id: __component.elId("food")
    }, "@textarea", component, 0, 0, {
      oninput: __component.d("input", "textUpdated", false)
    });

  out.e("label", {
      "class": "active",
      "for": __component.elId("food")
    }, "0", component, 1)
    .t("Fyll i ingredienser");
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
