// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/pages/admin/lab/lab.marko", function() {
      return module.exports;
    }),
    marko_component = require("./lab.component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    module_Surround = require("MarkoSurround"),
    Surround = module_Surround.default || module_Surround,
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_forEach = marko_helpers.f,
    marko_dynamicTag = marko_helpers.d,
    marko_createElement = marko_helpers.e,
    marko_const = marko_helpers.const,
    marko_const_nextId = marko_const("a1aa1d"),
    marko_node0 = marko_createElement("h1", null, "2", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("LABB"),
    marko_node1 = marko_createElement("thead", null, "6", null, 1, 0, {
        i: marko_const_nextId()
      })
      .e("tr", null, null, null, 5)
        .e("th", null, null, null, 1)
          .t("i.properties.preferredName")
        .e("th", null, null, null, 1)
          .t("name.properties.name")
        .e("th", null, null, null, 1)
          .t("name.labels")
        .e("th", null, null, null, 1)
          .t("n.properties.kJ")
        .e("th", null, null, null, 1)
          .t("ds.properties.name");

function render(input, out, __component, component, state) {
  var data = input;

  marko_dynamicTag(out, Surround, function() {
    return {
        content: {
            renderBody: function(out) {
              out.n(marko_node0, component);

              out.e("form", null, "3", component, 1, 0, {
                  onsubmit: __component.d("submit", event => event.preventDefault(), false)
                })
                .e("input", {
                    value: state.searchTerm,
                    type: "text"
                  }, "4", component, 0, 0, {
                    onchange: __component.d("change", "submit", false)
                  });

              out.be("table", null, "5", component);

              out.n(marko_node1, component);

              out.be("tbody", null, "13", component);

              var $for$0 = 0;

              marko_forEach(state.ingredients, function(hit) {
                var $keyScope$0 = "[" + (($for$0++) + "]");

                out.be("tr", null, "14" + $keyScope$0, component);

                out.be("td", null, "15" + $keyScope$0, component);

                out.h(hit.i.properties.preferredName);

                out.ee();

                out.be("td", null, "16" + $keyScope$0, component);

                out.h(hit.name.properties.name);

                out.ee();

                out.be("td", null, "17" + $keyScope$0, component);

                out.h(hit.name.labels.toString());

                out.ee();

                out.be("td", null, "18" + $keyScope$0, component);

                out.h(hit.n.properties.kJ);

                out.ee();

                out.be("td", null, "19" + $keyScope$0, component);

                out.h(hit.ds.properties.name);

                out.ee();

                out.ee();
              });

              out.ee();

              out.ee();
            }
          }
      };
  }, null, null, null, __component, "0");
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
