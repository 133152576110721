// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/components/kb-header/components/menu-desktop.marko", function() {
      return module.exports;
    }),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    header_links_template = require("./header-links/header-links.marko"),
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_loadTag = marko_helpers.t,
    header_links_tag = marko_loadTag(header_links_template),
    marko_attrs0 = {
        "class": "right hide-on-med-and-down"
      };

function render(input, out, __component, component, state) {
  var data = input;

  out.be("ul", marko_attrs0, "0", component);

  header_links_tag({}, out, __component, "1");

  out.ee();
}

marko_template._ = marko_renderer(render, {
    ___implicit: true,
    ___type: marko_componentType
  });

marko_template.Component = marko_defineComponent({}, marko_template._);
