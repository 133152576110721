/**
 * Client side session. Contains login state and shit.
 * Naive and stupid listeners, fix with something later
 */

import { default as FBPromise } from 'Services/facebook'
import { logger } from '@kb-front/common'
import { post } from '@kb-front/common/fetch'

import * as jwt from 'jsonwebtoken'
import * as Cookies from 'js-cookie'

const sessionListeners = []
const session = {}

const handleFBChange = response => {
  if (response.status === 'connected') {
    logger.debug('client side FB response', response)

    // Call server to validate FB access token
    post('/api/auth/facebook/validate-token', {
      access_token: response.authResponse.accessToken,
    }).then(validationResult => {
      logger.debug(['validation result', validationResult])

      readAndPushSessionFromJWT()
    })
  } else {
    logger.debug(response)
  }
}

const fbProfileImage = (fbId, fbToken) => `https://graph.facebook.com/${fbId}/picture?access_token=${fbToken}`

FBPromise.then(FB => {
  FB.Event.subscribe('auth.authResponseChange', handleFBChange)
})

const pushNotice = () => {
  sessionListeners.forEach(listener => {
    listener(session)
  })
}

const readAndPushSessionFromJWT = async () => {
  // TODO: define flow
  // I think we want:
  // 1) check cookie jwttoken and that its valid
  // 2) check "loginstatus" with facebook api
  // 3) otherwise, render login
  const jwtoken = Cookies.get('jwtoken')
  if (jwtoken) {
    const user = jwt.decode(jwtoken).user
    logger.debug('extracting user from jwt: ' + JSON.stringify(user))
    session.user = user

    // console.log(this.state.user)
    if (user.facebook) {
      session.user.profileImg = fbProfileImage(user.facebook.id, user.facebook.access_token)
      pushNotice()
      // const FB = await FBPromise

      // // Have to call getLoginStatus for the access_token to be set on FB _state_. Can't seem to find a way to
      // // just take this one from our own jwtoken and shove in there…?
      // await FB.getLoginStatus(loginStatus => {
      //   logger.debug('login status: ' + loginStatus)
      //   FB.api('/me', me => {
      //     session.user.name = me.name
      //     pushNotice()
      //   })
      // })
    } else {
      pushNotice()
    }
  } else {
    // If not logged in from _OUR_ side, don't consider logged in. FB.getLoginStatus will return "connected" even after
    // logged out from facebook SDK.
    // FBPromise.then(FB => {
    //   FB.getLoginStatus() // Will call the globally registered callback
    // })
  }
}

// DO this on page load
readAndPushSessionFromJWT()

export const logout = () => {
  Cookies.remove('jwtoken')
  FBPromise.then(FB => FB.logout(result => logger.debug('logged out facebook via jdk', result)))

  session.user = undefined
  pushNotice()
  // navigate('/')
}

export const acceptTerms = () =>
  post('/api/auth/accept-terms/').then(() => {
    readAndPushSessionFromJWT()
    return
  })

export const onSessionEvent = listener => {
  listener(session)
  sessionListeners.push(listener)
}
