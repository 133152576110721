const { calculateRows, textToRows } = require('Services/food-api')
const { history: History, ga } = require('window')
const cacheService = require('Services/cache').default

const { cache, cached } = cacheService('kostbevakningen-foodinput')

function gaDelayed(type, text) {
  setTimeout(() => {
    ga('send', 'event', 'Calculate', type, text)
  }, 1200)
}

module.exports = {
  onCreate(input) {
    this.state = {
      result: {},
      hasResult: false,
    }

    // Bidirectional update between cache and input params with prio to input params.
    // replace + with ' ' because old lift full submits encoded space with +.
    let textOnLoad = input.foodinput || input.params.foodinput ? input.params.foodinput.replace(/\+/g, ' ') : undefined
    if (textOnLoad) {
      cache(textOnLoad)
      gaDelayed('onLoad', textOnLoad)
    } else {
      textOnLoad = cached()
      gaDelayed('cached', textOnLoad)
      if (textOnLoad) this.updateHistory(textOnLoad)
    }

    if (textOnLoad) {
      this.textOnLoad = textOnLoad
      this.recalc(textOnLoad)
    } else {
      this.setState('hasResult', false)
    }
  },

  updateHistory(text) {
    History.replaceState(null, '', text.length ? '?foodinput=' + encodeURIComponent(text) : '/')
  },

  foodInputChanged(text) {
    this.updateHistory(text)
    cache(text)
    this.recalc(text)
  },

  recalc(text) {
    calculateRows(textToRows(text)).then(result => {
      this.setState('result', result)
      this.setState('hasResult', result && result.rows && result.rows.length > 0)
    })
  },
}
