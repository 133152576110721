// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/components/kb-header/kb-header.marko", function() {
      return module.exports;
    }),
    marko_component = require("./kb-header.component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    width_switch_template = require("../width-switch/index.marko"),
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_loadTag = marko_helpers.t,
    width_switch_tag = marko_loadTag(width_switch_template),
    menu_desktop_template = require("./components/menu-desktop.marko"),
    menu_desktop_tag = marko_loadTag(menu_desktop_template),
    menu_mobile_template = require("./components/menu-mobile.marko"),
    menu_mobile_tag = marko_loadTag(menu_mobile_template),
    marko_attrs0 = {
        id: "header"
      },
    marko_attrs1 = {
        "class": "  "
      },
    marko_attrs2 = {
        "class": "",
        role: "navigation"
      },
    marko_attrs3 = {
        "class": "nav-wrapper container"
      },
    marko_attrs4 = {
        "class": "brand-logo "
      },
    marko_createElement = marko_helpers.e,
    marko_const = marko_helpers.const,
    marko_const_nextId = marko_const("2502e6"),
    marko_node0 = marko_createElement("li", null, "13", null, 1, 0, {
        i: marko_const_nextId()
      })
      .e("a", {
          "class": "brand-logo2 ",
          id: "logo-container",
          href: "./"
        }, null, null, 1)
        .t("Kostbevakningen"),
    marko_attrs5 = {
        "class": "sidenav-trigger",
        "data-target": "nav-mobile",
        href: "#"
      },
    marko_node1 = marko_createElement("svg", {
        width: "32px",
        height: "32px",
        viewBox: "0 0 32 32",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }, "8", null, 1, 0, {
        i: marko_const_nextId()
      })
      .e("g", null, null, null, 3)
        .e("rect", {
            id: "Rectangle",
            "class": "nutrient-bar-1",
            x: "6",
            y: "8",
            width: "4",
            height: "23"
          }, null, null, 0)
        .e("rect", {
            id: "Rectangle",
            "class": "nutrient-bar-2",
            x: "13",
            y: "24",
            width: "4",
            height: "7"
          }, null, null, 0)
        .e("rect", {
            id: "Rectangle",
            "class": "nutrient-bar-3",
            x: "20",
            y: "14",
            width: "4",
            height: "17"
          }, null, null, 0),
    marko_node2 = marko_createElement("svg", {
        width: "32px",
        height: "32px",
        viewBox: "0 0 32 32",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }, "20", null, 1, 0, {
        i: marko_const_nextId()
      })
      .e("g", null, null, null, 3)
        .e("rect", {
            id: "Rectangle",
            "class": "nutrient-bar-1",
            x: "4",
            y: "7",
            width: "24",
            height: "3"
          }, null, null, 0)
        .e("rect", {
            id: "Rectangle",
            "class": "nutrient-bar-2",
            x: "4",
            y: "14",
            width: "18",
            height: "3"
          }, null, null, 0)
        .e("rect", {
            id: "Rectangle",
            "class": "nutrient-bar-3",
            x: "4",
            y: "21",
            width: "22",
            height: "3"
          }, null, null, 0);

function render(input, out, __component, component, state) {
  var data = input;

  out.be("header", marko_attrs0, "0", component);

  out.be("div", marko_attrs1, "1", component);

  out.be("nav", marko_attrs2, "2", component);

  out.be("div", marko_attrs3, "3", component);

  out.be("ul", marko_attrs4, "4", component);

  out.be("li", null, "5", component);

  width_switch_tag({
      containerId: "header",
      breakPoints: {
          l: 992
        },
      l: {
          renderBody: function(out) {
            out.n(marko_node1, component);
          }
        }
    }, out, __component, "6");

  out.ee();

  out.n(marko_node0, component);

  out.ee();

  width_switch_tag({
      containerId: "header",
      breakPoints: {
          l: 992,
          s: 0
        },
      l: {
          renderBody: function(out) {
            menu_desktop_tag({}, out, __component, "17");
          }
        },
      s: {
          renderBody: function(out) {
            menu_mobile_tag({}, out, __component, "mobile-menu");
          }
        }
    }, out, __component, "15");

  out.e("a", marko_attrs5, "19", component, 1, 0, {
      onclick: __component.d("click", "toggleNavbar", false)
    })
    .n(marko_node2, component);

  out.ee();

  out.ee();

  out.ee();

  out.ee();
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
