// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/components/fb-login-modal.marko", function() {
      return module.exports;
    }),
    marko_component = require("./fb-login-modal.component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    modal_template = require("../materialize-wrappers/modal/index.marko"),
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_loadTag = marko_helpers.t,
    modal_tag = marko_loadTag(modal_template),
    marko_createElement = marko_helpers.e,
    marko_const = marko_helpers.const,
    marko_const_nextId = marko_const("b5bddb"),
    marko_node0 = marko_createElement("div", null, "@modal-inner", null, 3, 0, {
        i: marko_const_nextId()
      })
      .e("p", null, null, null, 1)
        .t("Som medlem på Kostbevakningen får du tillgång till fler funktioner, som att till exempel spara dina sökningar.")
      .e("p", null, null, null, 1)
        .t("Vi kommer inte att posta något på din tidslinje utan ditt medgivande")
      .e("p", null, null, null, 1)
        .e("fb:login-button", {
            "auto-logout-link": "false",
            "button-type": "continue_with",
            size: "large",
            scope: "public_profile,email",
            "use-continue-as": "true"
          }, null, null, 0),
    marko_attrs0 = {
        "class": "modal-close waves-effect waves-green btn-flat"
      };

function render(input, out, __component, component, state) {
  var data = input;

  modal_tag({
      content: {
          renderBody: function(out) {
            out.n(marko_node0, component);
          }
        },
      footer: {
          renderBody: function(out) {
            out.e("button", marko_attrs0, "6", component, 1, 0, {
                onclick: __component.d("click", "close", false)
              })
              .t("Avbryt");
          }
        }
    }, out, __component, "modal");
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
