const window = require('window')
const { document } = window
const logger = require('@kb-front/common/logger').default

// Hotjar Tracking Code
if(typeof HOTJAR_KEY !== "undefined") {
  (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:HOTJAR_KEY,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  logger.debug('hotjar is ON')
} else {
  logger.debug('hotjar is OFF')
}
