// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/index.marko", function() {
      return module.exports;
    }),
    marko_component = require("./component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    router_template = require("../../../../node_modules/marko-router5/src/components/router/index.marko"),
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_loadTag = marko_helpers.t,
    router_tag = marko_loadTag(router_template);

function render(input, out, __component, component, state) {
  var data = input;

  const routes = [
  { name: 'home', path: '/', component: require('./pages/calculate') },
  { name: 'about', path: '/about', component: require('./pages/about.marko') },
  { name: 'help', path: '/help', component: require('./pages/help.marko') },
  { name: 'cookies', path: '/cookies', component: require('./pages/cookies.marko') },
  { name: 'diary', path: '/diary', component: require('./pages/diary/diary.marko') },
  { name: 'users', path: '/admin/users', component: require('./pages/admin/users.marko') },
  { name: 'lab', path: '/admin/lab', component: require('./pages/admin/lab/lab.marko') },
]

  router_tag({
      routes: routes,
      initialPath: true,
      options: {
          defaultRoute: "home"
        }
    }, out, __component, "0", [
    [
      "transition-success",
      "changedPage",
      false
    ]
  ]);
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
