// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/components/terms-modal.marko", function() {
      return module.exports;
    }),
    marko_component = require("./terms-modal.component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    modal_template = require("../materialize-wrappers/modal/index.marko"),
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_loadTag = marko_helpers.t,
    modal_tag = marko_loadTag(modal_template),
    marko_createElement = marko_helpers.e,
    marko_const = marko_helpers.const,
    marko_const_nextId = marko_const("81f216"),
    marko_node0 = marko_createElement("div", null, "@modal-inner", null, 1, 0, {
        i: marko_const_nextId()
      })
      .e("form", null, null, null, 6)
        .e("h1", null, null, null, 1)
          .t("Villkor för kostbevakningen.se")
        .e("p", null, null, null, 1)
          .t("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur pellentesque accumsan erat eu faucibus. Phasellus eu aliquet arcu. In nec est quis turpis dignissim venenatis. Donec accumsan congue tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Fusce luctus volutpat nibh feugiat ornare. Proin vulputate blandit faucibus. Donec quis hendrerit eros. Nulla a ex massa. Nullam interdum ex id feugiat pulvinar.")
        .e("p", null, null, null, 1)
          .t("Fusce nec euismod nisl, nec elementum urna. Duis justo ex, commodo eu ornare nec, consequat quis augue. Etiam leo nisi, volutpat eu diam eget, semper gravida nisi. Sed id luctus leo, in tempor nunc. Mauris suscipit, est nec consequat vestibulum, elit nunc hendrerit est, id scelerisque sapien risus ac ligula. Maecenas eget odio sapien. Quisque id felis ut purus eleifend pulvinar et vitae dui. Morbi eget dictum tellus. Morbi rutrum ultricies tincidunt. Praesent non gravida diam.")
        .e("p", null, null, null, 1)
          .t("Fusce nec euismod nisl, nec elementum urna. Duis justo ex, commodo eu ornare nec, consequat quis augue. Etiam leo nisi, volutpat eu diam eget, semper gravida nisi. Sed id luctus leo, in tempor nunc. Mauris suscipit, est nec consequat vestibulum, elit nunc hendrerit est, id scelerisque sapien risus ac ligula. Maecenas eget odio sapien. Quisque id felis ut purus eleifend pulvinar et vitae dui. Morbi eget dictum tellus. Morbi rutrum ultricies tincidunt. Praesent non gravida diam.")
        .e("p", null, null, null, 1)
          .t("Fusce nec euismod nisl, nec elementum urna. Duis justo ex, commodo eu ornare nec, consequat quis augue. Etiam leo nisi, volutpat eu diam eget, semper gravida nisi. Sed id luctus leo, in tempor nunc. Mauris suscipit, est nec consequat vestibulum, elit nunc hendrerit est, id scelerisque sapien risus ac ligula. Maecenas eget odio sapien. Quisque id felis ut purus eleifend pulvinar et vitae dui. Morbi eget dictum tellus. Morbi rutrum ultricies tincidunt. Praesent non gravida diam.")
        .e("div", {
            "class": "row"
          }, null, null, 1)
          .e("div", {
              "class": "input-field col s12"
            }, null, null, 1)
            .e("label", null, null, null, 3)
              .e("input", {
                  id: "accept-terms-checkbox",
                  type: "checkbox",
                  "class": "validate",
                  required: true,
                  "aria-required": "true"
                }, null, null, 0)
              .e("span", null, null, null, 1)
                .t("Jag accepterar")
              .e("span", {
                  "class": "helper-text",
                  "data-error": "Du måste acceptera villkoren för att fortsätta",
                  "data-success": "right"
                }, null, null, 0),
    marko_attrs0 = {
        "class": "left modal-close waves-effect waves-green btn-flat"
      },
    marko_attrs1 = {
        "class": "waves-effect waves-green btn"
      };

function render(input, out, __component, component, state) {
  var data = input;

  modal_tag({
      fixedFooter: true,
      content: {
          renderBody: function(out) {
            out.n(marko_node0, component);
          }
        },
      footer: {
          renderBody: function(out) {
            out.e("button", marko_attrs0, "14", component, 1, 0, {
                onclick: __component.d("click", "close", false)
              })
              .t("Avbryt");

            out.e("button", marko_attrs1, "15", component, 1, 0, {
                onclick: __component.d("click", "accept", false)
              })
              .t("Acceptera");
          }
        }
    }, out, __component, "modal");
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
