// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/pages/help.marko", function() {
      return module.exports;
    }),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    module_Surround = require("MarkoSurround"),
    Surround = module_Surround.default || module_Surround,
    kb_link_template = require("../components/kb-link/index.marko"),
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_loadTag = marko_helpers.t,
    kb_link_tag = marko_loadTag(kb_link_template),
    marko_dynamicTag = marko_helpers.d,
    marko_attrs0 = {
        "class": "row"
      },
    marko_attrs1 = {
        "class": "col s12"
      },
    marko_createElement = marko_helpers.e,
    marko_const = marko_helpers.const,
    marko_const_nextId = marko_const("f18aa1"),
    marko_node0 = marko_createElement("h1", null, "4", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Hjälp med Kostbevakningen"),
    marko_node1 = marko_createElement("p", {
        "class": "reader-friendly ingress"
      }, "5", null, 3, 0, {
        i: marko_const_nextId()
      })
      .t("Här har vi samlat vanliga frågor om Kostbevakningen. Om du inte får svar på just din fråga får du gärna kontakta oss på ")
      .e("a", {
          href: "mailto:support@kostbevakningen.se"
        }, null, null, 1)
        .t("support@kostbevakningen.se")
      .t(" – vi svarar så fort vi kan."),
    marko_attrs2 = {
        id: "search"
      },
    marko_node2 = marko_createElement("h2", null, "8", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Sökning"),
    marko_node3 = marko_createElement("p", {
        "class": "reader-friendly"
      }, "9", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("I sökrutan skriver man in ingredienser som om det vore ett recept. En ingrediens per rad. Till exempel:"),
    marko_node4 = marko_createElement("h4", null, "10", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Exempel"),
    marko_node5 = marko_createElement("p", null, "11", null, 7, 0, {
        i: marko_const_nextId()
      })
      .t("100 gram vetemjöl")
      .e("br", null, null, null, 0)
      .t(" 50 gram socker")
      .e("br", null, null, null, 0)
      .t(" 2 ägg")
      .e("br", null, null, null, 0)
      .t(" 1 dl mjölk"),
    marko_attrs3 = {
        id: "searchWeight"
      },
    marko_node6 = marko_createElement("h3", null, "17", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Söka på vikt"),
    marko_node7 = marko_createElement("p", {
        "class": "reader-friendly"
      }, "18", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Vår ambition är att man ska kunna skriva in det man ätit som om man hade skrivet ett vanligt recept."),
    marko_node8 = marko_createElement("h4", null, "19", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Exempel 1"),
    marko_node9 = marko_createElement("p", null, "20", null, 6, 0, {
        i: marko_const_nextId()
      })
      .t("0,5 kilo kaffe")
      .e("br", null, null, null, 0)
      .t(" 2 hekto te")
      .e("br", null, null, null, 0)
      .t(" 30 gr socker")
      .e("br", null, null, null, 0),
    marko_node10 = marko_createElement("div", {
        "class": "sub-section"
      }, "25", null, 0, 0, {
        i: marko_const_nextId()
      }),
    marko_node11 = marko_createElement("h4", null, "26", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Exempel 2"),
    marko_node12 = marko_createElement("p", null, "27", null, 6, 0, {
        i: marko_const_nextId()
      })
      .t("0.5 kg kaffe")
      .e("br", null, null, null, 0)
      .t(" 2 hg te")
      .e("br", null, null, null, 0)
      .t(" 30 g socker")
      .e("br", null, null, null, 0),
    marko_attrs4 = {
        id: "searchVolume"
      },
    marko_node13 = marko_createElement("h3", null, "33", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Söka på volym"),
    marko_node14 = marko_createElement("p", {
        "class": "reader-friendly"
      }, "34", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("En del matvaror är mer naturligt att skriva i volym snarare än vikt. Vi har lagt in de vanligaste måttenheterna."),
    marko_node15 = marko_createElement("h4", null, "35", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Exempel"),
    marko_node16 = marko_createElement("p", null, "36", null, 10, 0, {
        i: marko_const_nextId()
      })
      .t("1 dl grädde")
      .e("br", null, null, null, 0)
      .t(" 2 l vatten")
      .e("br", null, null, null, 0)
      .t(" 1 msk bakpulver")
      .e("br", null, null, null, 0)
      .t(" 3 teskedar socker")
      .e("br", null, null, null, 0)
      .t(" 2 krm salt")
      .e("br", null, null, null, 0),
    marko_attrs5 = {
        id: "searchUnits"
      },
    marko_node17 = marko_createElement("h3", null, "44", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Söka på antal"),
    marko_node18 = marko_createElement("p", {
        "class": "reader-friendly"
      }, "45", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Saker som frukt, grönsaker och en del andra varor kan vara enklare att hantera i antal."),
    marko_node19 = marko_createElement("h4", null, "46", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Exempel"),
    marko_node20 = marko_createElement("p", null, "47", null, 7, 0, {
        i: marko_const_nextId()
      })
      .t("1 banan")
      .e("br", null, null, null, 0)
      .t(" 2 ägg")
      .e("br", null, null, null, 0)
      .t(" 0,5 gurka")
      .e("br", null, null, null, 0)
      .t(" 3 stycken paprikor"),
    marko_attrs6 = {
        id: "searchSpecialUnits"
      },
    marko_node21 = marko_createElement("h3", null, "53", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Söka på enheter"),
    marko_node22 = marko_createElement("p", {
        "class": "reader-friendly"
      }, "54", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("För vissa typer av varor och produkter finns det enheter som beror på vilken slags vara eller produkt det rör sig om."),
    marko_node23 = marko_createElement("h4", null, "55", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Exempel"),
    marko_node24 = marko_createElement("p", null, "56", null, 7, 0, {
        i: marko_const_nextId()
      })
      .t("1 kruka basilika")
      .e("br", null, null, null, 0)
      .t(" 1 glas vin")
      .e("br", null, null, null, 0)
      .t(" 2 nävar nötter")
      .e("br", null, null, null, 0)
      .t(" 0,5 paket krossade tomater"),
    marko_attrs7 = {
        id: "searchCountingWords"
      },
    marko_node25 = marko_createElement("h3", null, "62", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Räkneord, bråktal och intervall"),
    marko_node26 = marko_createElement("p", {
        "class": "reader-friendly"
      }, "63", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("För att underlätta så är det möjligt att använda sig av vissa räkneord, bråktal och intervall."),
    marko_node27 = marko_createElement("h4", null, "64", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Exempel"),
    marko_node28 = marko_createElement("p", null, "65", null, 12, 0, {
        i: marko_const_nextId()
      })
      .t("ett dussin ägg")
      .e("br", null, null, null, 0)
      .t(" två kilo köttfärs")
      .e("br", null, null, null, 0)
      .t(" en halv påse ostbågar")
      .e("br", null, null, null, 0)
      .t(" 4-5 msk tabasco")
      .e("br", null, null, null, 0)
      .t(" 2 1/2 gul lök")
      .e("br", null, null, null, 0)
      .t(" ⅔ flaska vin")
      .e("br", null, null, null, 0),
    marko_attrs8 = {
        id: "calculations"
      },
    marko_node29 = marko_createElement("h2", null, "74", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Beräkningar"),
    marko_node30 = marko_createElement("p", {
        "class": "reader-friendly"
      }, "75", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Vi använder oss av Livsmedelsverkets beräkningsmodell, som innebär att även kostfibrer och alkohol tas med i beräkningen av den totala energin. "),
    marko_node31 = marko_createElement("p", {
        "class": "reader-friendly"
      }, "76", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Den procentuella fördelningen som visas är i energiprocent. Det innebär att procenttalet anger hur stor del av energin som kommer från proteiner, fett, kolhydrater, kostfibrer eller akohol. Detta tal skiljer sig, ibland markant, från viktprocent."),
    marko_attrs9 = {
        "class": "reader-friendly"
      },
    marko_node32 = marko_createElement("p", {
        "class": "reader-friendly"
      }, "79", null, 3, 0, {
        i: marko_const_nextId()
      })
      .t("I kommande versioner kommer vi visa fler beräkningar för den som önskar. ")
      .e("a", {
          href: "mailto:support@kostbevakningen.se"
        }, null, null, 1)
        .t("Hör gärna av dig")
      .t(" till oss om du saknar någon specifik beräkning."),
    marko_attrs10 = {
        id: "food"
      },
    marko_node33 = marko_createElement("h2", null, "82", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Livsmedel"),
    marko_node34 = marko_createElement("p", {
        "class": "reader-friendly"
      }, "83", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Mycket av det du äter, men inte allt, finns i vår databas. Det som saknas kommer bli rödmarkerat vid sökningar. Vi loggar all mat som inte hittats, så att vi, i de flesta fall, kan lägga in det dagen efter. Med andra ord – om maten du söker inte finns idag så finns den troligen i morgon!"),
    marko_node35 = marko_createElement("p", {
        "class": "reader-friendly"
      }, "84", null, 3, 0, {
        i: marko_const_nextId()
      })
      .t("Om det är någon speciell mat du saknar, som du verkligen inte klarar dig utan, så kan du alltid ")
      .e("a", {
          href: "mailto:support@kostbevakningen.se"
        }, null, null, 1)
        .t("maila oss")
      .t(" och berätta det! Om du dessutom bifogar näringsvärdena så går det ännu snabbare! Skicka gärna med en länk till källan om möjligt, eller skriv att du hämtat det från paketet om det är så du gjort."),
    marko_node36 = marko_createElement("p", {
        "class": "reader-friendly"
      }, "86", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Utöver detta så kan man även skriva in protein, fett, kolhydrater, fibrer och alkohol som helt vanliga ingredienser."),
    marko_node37 = marko_createElement("h4", null, "87", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Exempel"),
    marko_node38 = marko_createElement("p", null, "88", null, 9, 0, {
        i: marko_const_nextId()
      })
      .t("100 gram protein")
      .e("br", null, null, null, 0)
      .t(" 100 gram fett")
      .e("br", null, null, null, 0)
      .t(" 100 gram kolhydrater")
      .e("br", null, null, null, 0)
      .t(" 100 gram fibrer")
      .e("br", null, null, null, 0)
      .t(" 100 gram alkohol");

function render(input, out, __component, component, state) {
  var data = input;

  marko_dynamicTag(out, Surround, function() {
    return {
        content: {
            renderBody: function(out) {
              out.be("div", marko_attrs0, "2", component);

              out.be("div", marko_attrs1, "3", component);

              out.n(marko_node0, component);

              out.n(marko_node1, component);

              out.be("section", marko_attrs2, "7", component);

              out.n(marko_node2, component);

              out.n(marko_node3, component);

              out.n(marko_node4, component);

              out.n(marko_node5, component);

              kb_link_tag({
                  href: "/?foodinput=100%20gram%20vetemjöl%0A50%20gram%20socker%0A2%20ägg%0A1%20dl%20mjölk",
                  class: "btn-small",
                  renderBody: function(out) {
                    out.t("Testa sökningen");
                  }
                }, out, __component, "15");

              out.ee();

              out.be("section", marko_attrs3, "16", component);

              out.n(marko_node6, component);

              out.n(marko_node7, component);

              out.n(marko_node8, component);

              out.n(marko_node9, component);

              kb_link_tag({
                  href: "/?foodinput=0%2C5%20kilo%20kaffe%0A2%20hekto%20te%0A30%20gr%20socker",
                  class: "btn-small",
                  renderBody: function(out) {
                    out.t("Testa sökningen");
                  }
                }, out, __component, "24");

              out.n(marko_node10, component);

              out.n(marko_node11, component);

              out.n(marko_node12, component);

              kb_link_tag({
                  href: "/?foodinput=0.5%20kg%20kaffe%0A2%20hg%20te%0A30%20g%20socker",
                  class: "btn-small",
                  renderBody: function(out) {
                    out.t("Testa sökningen");
                  }
                }, out, __component, "31");

              out.ee();

              out.be("section", marko_attrs4, "32", component);

              out.n(marko_node13, component);

              out.n(marko_node14, component);

              out.n(marko_node15, component);

              out.n(marko_node16, component);

              kb_link_tag({
                  href: "/?foodinput=1%20dl%20grädde%0A2%20l%20vatten%0A1%20msk%20bakpulver%0A3%20teskedar%20socker%0A2%20krm%20salt",
                  class: "btn-small",
                  renderBody: function(out) {
                    out.t("Testa sökningen");
                  }
                }, out, __component, "42");

              out.ee();

              out.be("section", marko_attrs5, "43", component);

              out.n(marko_node17, component);

              out.n(marko_node18, component);

              out.n(marko_node19, component);

              out.n(marko_node20, component);

              kb_link_tag({
                  href: "/?foodinput=1%20banan%0A2%20ägg%0A0%2C5%20gurka%0A3%20stycken%20paprikor",
                  class: "btn-small",
                  renderBody: function(out) {
                    out.t("Testa sökningen");
                  }
                }, out, __component, "51");

              out.t(" ");

              out.ee();

              out.be("section", marko_attrs6, "52", component);

              out.n(marko_node21, component);

              out.n(marko_node22, component);

              out.n(marko_node23, component);

              out.n(marko_node24, component);

              kb_link_tag({
                  href: "/?foodinput=1%20kruka%20basilika%0A1%20glas%20vin%0A2%20nävar%20nötter%0A0%2C5%20paket%20krossade%20tomater",
                  class: "btn-small",
                  renderBody: function(out) {
                    out.t("Testa sökningen");
                  }
                }, out, __component, "60");

              out.ee();

              out.be("section", marko_attrs7, "61", component);

              out.n(marko_node25, component);

              out.n(marko_node26, component);

              out.n(marko_node27, component);

              out.n(marko_node28, component);

              kb_link_tag({
                  href: "/?foodinput=ett%20dussin%20ägg%0Atvå%20kilo%20köttfärs%0Aen%20halv%20påse%20ostbågar%0A4-5%20msk%20tabasco%0A2%201%2F2%20gul%20lök%0A⅔%20flaska%20vin",
                  class: "btn-small",
                  renderBody: function(out) {
                    out.t("Testa sökningen");
                  }
                }, out, __component, "72");

              out.ee();

              out.be("section", marko_attrs8, "73", component);

              out.n(marko_node29, component);

              out.n(marko_node30, component);

              out.n(marko_node31, component);

              out.be("p", marko_attrs9, "77", component);

              out.t("Om man till exempel tar ");

              kb_link_tag({
                  href: "/?foodinput=1%20dl%20lättmjölk",
                  renderBody: function(out) {
                    out.t("1 dl lättmjölk");
                  }
                }, out, __component, "78");

              out.t(", så är det 11 % fett i den enligt Kostbevakningen. Hur kan det komma sig? På paketet står det ju 0,5 % fett. Anledningen är att av den energi kroppen kan ta upp från mjölken (det mesta av mjölken är faktiskt vatten), så kommer 11 % från fett, 37 % från protein och 52 % från kolhydrater. Eftersom fett dessutom är mer energitätt än allt annat, så kommer energiprocenten fett alltid vara högre än viktprocenten, som är det som anges på förpackningarna.");

              out.ee();

              out.n(marko_node32, component);

              out.ee();

              out.be("section", marko_attrs10, "81", component);

              out.n(marko_node33, component);

              out.n(marko_node34, component);

              out.n(marko_node35, component);

              out.n(marko_node36, component);

              out.n(marko_node37, component);

              out.n(marko_node38, component);

              kb_link_tag({
                  href: "/?foodinput=100%20gram%20protein%0A100%20gram%20fett%0A100%20gram%20kolhydrater%0A100%20gram%20fibrer%0A100%20gram%20alkohol",
                  class: "btn-small",
                  renderBody: function(out) {
                    out.t("Testa sökningen");
                  }
                }, out, __component, "93");

              out.ee();

              out.ee();

              out.ee();
            }
          }
      };
  }, null, null, null, __component, "0");
}

marko_template._ = marko_renderer(render, {
    ___implicit: true,
    ___type: marko_componentType
  });

marko_template.Component = marko_defineComponent({}, marko_template._);
