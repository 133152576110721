const ga = require('googleanalytics')
module.exports = {
  calculate() {
    var foodText = this.getComponent('food-text-area').getText()
    ga('send', 'event', 'Calculate', 'Button', foodText)
    this.textUpdated(foodText)
  },
  reset() {
    ga('send', 'event', 'Clear', 'Button')
    this.getComponent('food-text-area').reset()
    this.textUpdated('')
  },
  textUpdated(text) {
    this.emit('food-input-changed', text)
  },
}
