import winston from 'winston'
const { createLogger, format, transports } = winston

const level = 'debug'

const logger = createLogger({
  level,
  format: format.combine(format.splat(), format.colorize(), format.simple()),
  defaultMeta: { service: 'kb-front' },
})

logger.add(new transports.Console())

export default logger
