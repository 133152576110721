// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    marko_component = {
    recalculate: function (input) {
        const inputGraphHeight = 20;
        const inputBarWidth = 4;
        const inputBarDistance = 1;
        const successFilter = row => row && row.result === 'success';
        const largestOfAll = input.maxNutrient ? input.maxNutrient : Math.max(...input.allRows.filter(successFilter).map(r => maxNutrient(r)));
        const calculateBarHeight = currentNutrient => Math.max(Math.round(currentNutrient / largestOfAll * inputGraphHeight), 1);
        const macroNutrs = input.currentRow['kJ'];
        const calculatedBars = [
            macroNutrs.protein,
            macroNutrs.fat,
            macroNutrs.carbs,
            macroNutrs.fibres,
            macroNutrs.alcohol
        ].map(n => ({ height: calculateBarHeight(n) }));
        const calculatedGraphWidth = calculatedBars.length * (inputBarWidth + inputBarDistance) - 1;
        this.state = {
            graphHeight: inputGraphHeight,
            graphWidth: calculatedGraphWidth,
            barWidth: inputBarWidth,
            barDistance: inputBarDistance,
            bars: calculatedBars
        };
    },
    onCreate: function (input) {
        this.recalculate(input);
    },
    onInput: function (input, out) {
        this.recalculate(input);
    }
},
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/components/nutrient-bars.marko", function() {
      return module.exports;
    }),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    module_nutrientCalc_module = require("Services/nutrient-calc"),
    nutrientCalc_module = module_nutrientCalc_module.default || module_nutrientCalc_module,
    maxNutrient = module_nutrientCalc_module.maxNutrient,
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_forEach = marko_helpers.f,
    marko_classAttr = marko_helpers.ca,
    marko_attrs0 = {
        stroke: "none",
        "stroke-width": "1",
        fill: "none",
        "fill-rule": "evenodd"
      };

function render(input, out, __component, component, state) {
  var data = input;

  out.be("svg", {
      width: state.graphWidth + "px",
      height: state.graphHeight + "px",
      viewBox: (("0 0 " + state.graphWidth) + " ") + state.graphHeight,
      version: "1.1",
      xmlns: "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }, "0", component);

  out.be("g", marko_attrs0, "1", component);

  var $for$0 = 0;

  marko_forEach(state.bars, function(bar, index) {
    var $keyScope$0 = "[" + (($for$0++) + "]");

    out.e("rect", {
        "class": marko_classAttr("nutrient-bar-" + (index + 1)),
        x: (state.barWidth + state.barDistance) * index,
        y: state.graphHeight - bar.height,
        width: state.barWidth,
        height: bar.height
      }, "2" + $keyScope$0, component, 0);
  });

  out.ee();

  out.ee();
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
