// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/components/kb-header/components/header-links/header-links.marko", function() {
      return module.exports;
    }),
    marko_component = require("./header-links.component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    nm = require("./components/nested-menu.marko"),
    nmm = require("./components/nested-menu-mobile.marko"),
    kb_link_template = require("../../../kb-link/index.marko"),
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_loadTag = marko_helpers.t,
    kb_link_tag = marko_loadTag(kb_link_template),
    marko_dynamicTag = marko_helpers.d,
    marko_createElement = marko_helpers.e,
    marko_const = marko_helpers.const,
    marko_const_nextId = marko_const("8c41ee"),
    marko_node0 = marko_createElement("i", {
        "class": "material-icons right"
      }, "18", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("arrow_drop_down");

function render(input, out, __component, component, state) {
  var data = input;

  out.be("li", null, "0", component);

  kb_link_tag({
      href: "/",
      parentClass: true,
      renderBody: function(out) {
        out.t("Sök mat");
      }
    }, out, __component, "1");

  out.ee();

  if (state.user) {
    out.be("li", null, "2", component);

    kb_link_tag({
        href: "/diary",
        parentClass: true,
        renderBody: function(out) {
          out.t("Dagbok");
        }
      }, out, __component, "3");

    out.ee();
  }

  out.be("li", null, "4", component);

  kb_link_tag({
      href: "/help",
      parentClass: true,
      renderBody: function(out) {
        out.t("Hjälp");
      }
    }, out, __component, "5");

  out.ee();

  if (component.showLogin()) {
    if (state.user && state.user.isAdmin) {
      marko_dynamicTag(out, input.mobile ? nmm : nm, function() {
        return {
            id: "admin-menu",
            link: "Admin"
          };
      }, function(out) {
        out.be("li", null, "7", component);

        kb_link_tag({
            href: "/admin/users",
            parentClass: true,
            renderBody: function(out) {
              out.t("Users");
            }
          }, out, __component, "8");

        out.ee();

        out.be("li", null, "9", component);

        kb_link_tag({
            href: "/about",
            parentClass: true,
            renderBody: function(out) {
              out.t("Om");
            }
          }, out, __component, "10");

        out.ee();

        out.be("li", null, "11", component);

        kb_link_tag({
            href: "/admin/lab",
            parentClass: true,
            renderBody: function(out) {
              out.t("Lab");
            }
          }, out, __component, "12");

        out.ee();
      }, null, null, __component, "6");
    }

    if (state.user) {
      marko_dynamicTag(out, input.mobile ? nmm : nm, function() {
        return {
            id: "logged-in-menu",
            class: "logged-in-menu",
            linkContent: {
                renderBody: function(out) {
                  if (state.user.profileImg && (!input.mobile)) {
                    out.e("img", {
                        src: state.user.profileImg,
                        width: "32",
                        height: "32",
                        "class": "responsive-img circle"
                      }, "17", component, 0);
                  }

                  out.n(marko_node0, component);

                  out.t(state.user.name);
                }
              }
          };
      }, function(out) {
        out.e("li", null, "14", component, 1)
          .e("a", null, "15", component, 1, 0, {
              onclick: __component.d("click", "logout", false)
            })
            .t("Logga ut");
      }, null, null, __component, "13");
    } else {
      out.e("li", null, "19", component, 1)
        .e("a", null, "20", component, 1, 0, {
            onclick: __component.d("click", "openFbLoginModal", false)
          })
          .t("Logga in");
    }
  }
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
