const window = require('window')
const { document, navigator } = window

module.exports = {
  onMount() {
    // If we start up from iOS home screen, we add some extra margin in the bottom to comply with Jimmy's iPhone X.
    if ('standalone' in navigator && navigator.standalone) {
      document.getElementsByClassName('page-footer')[0].className += ' big-foot'
    }
  },
}
