// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/components/kb-header/components/header-links/components/nested-menu.marko", function() {
      return module.exports;
    }),
    marko_component = require("./nested-menu.component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_dynamicTag = marko_helpers.d,
    marko_classAttr = marko_helpers.ca;

function render(input, out, __component, component, state) {
  var data = input;

  out.be("ul", {
      id: input.id,
      "class": "dropdown-content"
    }, "0", component, null, 1);

  marko_dynamicTag(out, input.renderBody, null, null, null, null, __component, "1");

  out.ee();

  out.be("li", {
      "class": marko_classAttr(input.class)
    }, "2", component, null, 1);

  out.be("a", {
      href: input.id,
      "data-target": input.id,
      "class": "sidenav-close"
    }, "@trigger", component);

  out.t(input.link);

  out.t(" ");

  marko_dynamicTag(out, input.linkContent, null, null, null, null, __component, "3");

  out.ee();

  out.ee();
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
