// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/pages/calculate.marko", function() {
      return module.exports;
    }),
    marko_component = require("./calculate.component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    module_Surround = require("MarkoSurround"),
    Surround = module_Surround.default || module_Surround,
    food_input_template = require("../components/food-input/index.marko"),
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_loadTag = marko_helpers.t,
    food_input_tag = marko_loadTag(food_input_template),
    food_output_template = require("../components/food-output/index.marko"),
    food_output_tag = marko_loadTag(food_output_template),
    onboarding_template = require("../components/onboarding.marko"),
    onboarding_tag = marko_loadTag(onboarding_template),
    facebook_like_box_template = require("../components/facebook-like-box.marko"),
    facebook_like_box_tag = marko_loadTag(facebook_like_box_template),
    marko_dynamicTag = marko_helpers.d,
    marko_attrs0 = {
        "class": "row"
      },
    marko_attrs1 = {
        "class": "col s12 l4 food-input"
      },
    marko_attrs2 = {
        "class": "col offset-l1 s12 l7"
      };

function render(input, out, __component, component, state) {
  var data = input;

  marko_dynamicTag(out, Surround, function() {
    return {
        content: {
            renderBody: function(out) {
              out.be("div", marko_attrs0, "2", component);

              out.be("div", marko_attrs1, "3", component);

              food_input_tag({
                  text: component.textOnLoad
                }, out, __component, "4", [
                [
                  "food-input-changed",
                  "foodInputChanged",
                  false
                ]
              ]);

              out.ee();

              out.be("div", marko_attrs2, "5", component);

              if (state.hasResult) {
                food_output_tag({
                    response: state.result
                  }, out, __component, "food-output");
              } else {
                onboarding_tag({}, out, __component, "6");
              }

              out.ee();

              out.ee();

              facebook_like_box_tag({}, out, __component, "7");
            }
          }
      };
  }, null, null, null, __component, "0");
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
