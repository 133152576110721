// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/components/food-input/index.marko", function() {
      return module.exports;
    }),
    marko_component = require("./component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    smart_textarea_template = require("../smart-textarea/index.marko"),
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_loadTag = marko_helpers.t,
    smart_textarea_tag = marko_loadTag(smart_textarea_template),
    marko_attrs0 = {
        "class": "input-field"
      },
    marko_attrs1 = {
        "class": "action-buttons"
      },
    marko_attrs2 = {
        id: "calculateButton",
        "class": "btn waves-effect waves-light",
        tabindex: "2",
        name: "action"
      },
    marko_attrs3 = {
        id: "resetButton",
        "class": "btn-flat right",
        type: "reset",
        tabindex: "3",
        name: "action"
      },
    marko_createElement = marko_helpers.e,
    marko_const = marko_helpers.const,
    marko_const_nextId = marko_const("bb785e"),
    marko_node0 = marko_createElement("i", {
        "class": "material-icons right"
      }, "3", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("local_dining"),
    marko_node1 = marko_createElement("i", {
        "class": "material-icons right"
      }, "5", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("clear ");

function render(input, out, __component, component, state) {
  var data = input;

  out.be("div", marko_attrs0, "0", component);

  smart_textarea_tag({
      text: input.text
    }, out, __component, "food-text-area", [
    [
      "text-updated",
      "textUpdated",
      false
    ]
  ]);

  out.ee();

  if (!input.hideButtons) {
    out.e("div", marko_attrs1, "1", component, 2)
      .e("button", marko_attrs2, "2", component, 2, 0, {
          onclick: __component.d("click", "calculate", false)
        })
        .t("Beräkna ")
        .n(marko_node0, component)
      .e("button", marko_attrs3, "4", component, 2, 0, {
          onclick: __component.d("click", "reset", false)
        })
        .t("Rensa ")
        .n(marko_node1, component);
  }
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
