// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/components/food-output/components/row-output.marko", function() {
      return module.exports;
    }),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    marko_attrs0 = {
        "class": "right-align"
      },
    marko_attrs1 = {
        "class": "right-align"
      },
    marko_attrs2 = {
        "class": "right-align"
      },
    marko_attrs3 = {
        "class": "right-align"
      },
    marko_attrs4 = {
        "class": "right-align"
      };

function render(input, out, __component, component, state) {
  var data = input;

  out.be("td", marko_attrs0, "0", component);

  out.h(input.format(input.totals.protein));

  out.ee();

  out.be("td", marko_attrs1, "1", component);

  out.h(input.format(input.totals.fat));

  out.ee();

  out.be("td", marko_attrs2, "2", component);

  out.h(input.format(input.totals.carbs));

  out.ee();

  if (input.full) {
    out.be("td", marko_attrs3, "3", component);

    out.h(input.format(input.totals.fibres));

    out.ee();

    out.be("td", marko_attrs4, "4", component);

    out.h(input.format(input.totals.alcohol));

    out.ee();
  }
}

marko_template._ = marko_renderer(render, {
    ___implicit: true,
    ___type: marko_componentType
  });

marko_template.Component = marko_defineComponent({}, marko_template._);
