import { logger } from '@kb-front/common'
import * as session from 'Services/session'
import { document } from 'window'
export default class {
  onCreate() {
    logger.debug('terms-modal created')
  }

  onMount() {
    session.onSessionEvent(session => {
      if (session.user && !session.user.acceptedTerms) {
        this.getComponent('modal').emit('open')
      }
    })
  }

  close() {
    this.getComponent('modal').emit('close')
  }

  accept() {
    const checkbox = document.getElementById('accept-terms-checkbox') // key doesn't work
    if (checkbox.checked) {
      session.acceptTerms().then(() => {
        this.getComponent('modal').emit('close')
      })
    } else {
      const errorDiv = document.createElement('div')
      errorDiv.textContent = 'Du måste acceptera villkoren för att fortsätta'
      errorDiv.classList.add('red-text')
      checkbox.parentNode.insertBefore(errorDiv, checkbox)
    }
  }
}
