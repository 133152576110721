const M = require('M')

// TODO: Make a globally accessable singleton modal that's like a slot with an api

module.exports = {
  onMount() {
    const elem = this.getEl()
    this.instance = M.Modal.init(elem, {})

    this.on('open', this.open)
    this.on('close', this.close)
  },
  open() {
    this.instance.open()
  },
  close() {
    this.instance.close()
  },
}
