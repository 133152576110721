// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/pages/admin/users.marko", function() {
      return module.exports;
    }),
    marko_component = require("./users.component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    Surround = require("../../surround.marko"),
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_forEach = marko_helpers.f,
    marko_dynamicTag = marko_helpers.d,
    marko_createElement = marko_helpers.e,
    marko_const = marko_helpers.const,
    marko_const_nextId = marko_const("eb1d2e"),
    marko_node0 = marko_createElement("h1", null, "2", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Users"),
    marko_node1 = marko_createElement("thead", null, "4", null, 1, 0, {
        i: marko_const_nextId()
      })
      .e("tr", null, null, null, 3)
        .e("th", null, null, null, 1)
          .t("Name")
        .e("th", null, null, null, 1)
          .t("Avatar")
        .e("th", null, null, null, 1)
          .t("Admin");

function render(input, out, __component, component, state) {
  var data = input;

  const users = state.users

  marko_dynamicTag(out, Surround, function() {
    return {
        content: {
            renderBody: function(out) {
              out.n(marko_node0, component);

              out.be("table", null, "3", component);

              out.n(marko_node1, component);

              var $for$0 = 0;

              marko_forEach(users, function(user) {
                var $keyScope$0 = "[" + (($for$0++) + "]");

                out.be("tr", null, "9" + $keyScope$0, component);

                out.e("td", null, "10" + $keyScope$0, component, 1)
                  .t(user.name);

                out.be("td", null, "11" + $keyScope$0, component);

                if (user.facebook) {
                  out.e("img", {
                      "class": "responsive-img circle",
                      width: "32",
                      height: "32",
                      src: (("https://graph.facebook.com/" + user.facebook.id) + "/picture?access_token=") + user.facebook.access_token,
                      title: user.facebook.id
                    }, "12" + $keyScope$0, component, 0);
                }

                out.ee();

                out.e("td", null, "13" + $keyScope$0, component, 1)
                  .t(user.isAdmin);

                out.ee();
              });

              out.t(" ");

              out.ee();
            }
          }
      };
  }, null, null, null, __component, "0");
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
