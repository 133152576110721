const window = require('window')

const storage = window.localStorage

export default id => ({
  cache: text => {
    if (storage) {
      storage.setItem(id, text)
    }
  },

  cached: () => {
    if (storage) {
      try {
        return storage.getItem(id)
      } catch (ex) {
        return undefined
      }
    }
  },
})
