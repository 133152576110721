const window = require('window')
const { document } = window

const ResizeSensor = require('css-element-queries/src/ResizeSensor')
const debounce = require('lodash-es/debounce').default

module.exports = {
  onInput() {
    this.state = {
      blocks: {},
    }
  },

  rerender(el) {
    const containerWidth = el.clientWidth
    Object.keys(this.input.breakPoints).forEach(
      key => (this.state.blocks[key] = containerWidth >= this.input.breakPoints[key])
    )
    this.setStateDirty('blocks')
  },

  onMount() {
    const el = document.getElementById(this.input.containerId)
    this.rerender(el)

    new ResizeSensor(
      el,
      debounce(() => {
        this.rerender(el)
      }, 100)
    )
  },

  onDestroy() {},
}
