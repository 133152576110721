// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/pages/cookies.marko", function() {
      return module.exports;
    }),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    module_Surround = require("MarkoSurround"),
    Surround = module_Surround.default || module_Surround,
    kb_link_template = require("../components/kb-link/index.marko"),
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_loadTag = marko_helpers.t,
    kb_link_tag = marko_loadTag(kb_link_template),
    marko_dynamicTag = marko_helpers.d,
    marko_attrs0 = {
        "class": "row"
      },
    marko_attrs1 = {
        "class": "col"
      },
    marko_createElement = marko_helpers.e,
    marko_const = marko_helpers.const,
    marko_const_nextId = marko_const("284a28"),
    marko_node0 = marko_createElement("h1", null, "4", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Kakpolicy för Kostbevakningen"),
    marko_node1 = marko_createElement("p", {
        "class": "flow-text"
      }, "5", null, 2, 0, {
        i: marko_const_nextId()
      })
      .t("Detta är Kostbevakningens Kakpolicy, tillgänglig från ")
      .e("a", {
          href: "/cookies"
        }, null, null, 1)
        .t("http://kostbevakningen.se/cookies"),
    marko_node2 = marko_createElement("h2", null, "7", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Vad är kakor?"),
    marko_node3 = marko_createElement("p", {
        "class": "flow-text"
      }, "8", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Precis som nästan alla andra webbplatser, så använder Kostbevakningen sig av kakor. Kakorna är små filer som sparas på din dator för att förbättra användarupplevelsen. Den här sidan beskiver vad de innehåller för data, hur vi använder den och varför vi behöver spara den här typen av information. Vi beskriver också hur du kan slå av möjligheten att lagra kakor, men det är inget vi rekommenderar, då Kostbevakningen inte fungerar som det är tänkt utan dem. Slutligen kommer vi också ge dig ett recept på småkakor."),
    marko_node4 = marko_createElement("p", {
        "class": "flow-text"
      }, "9", null, 2, 0, {
        i: marko_const_nextId()
      })
      .t("Du kan läsa mer om kakor till exempel här: ")
      .e("a", {
          href: "http://cookielagen.se/cookies/"
        }, null, null, 1)
        .t("http://cookielagen.se/cookies/"),
    marko_node5 = marko_createElement("h2", null, "11", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Hur Kostbevakningen använder kakor"),
    marko_node6 = marko_createElement("p", {
        "class": "flow-text"
      }, "12", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Vi använder kakor för lite olika anledningar som vi listar nedan. "),
    marko_node7 = marko_createElement("h2", null, "13", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Stänga av kakor?"),
    marko_node8 = marko_createElement("p", {
        "class": "flow-text"
      }, "14", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Du kan stänga av kakor genom att ställa in detta i den webbläsare. Gå till hjälpen för din webbläsare för att att lära dig mer om hur du gör. Det är dock ingenting vi rekommenderar, då Kostbevakningens funktionalitet bygger på att kakor är påslagna."),
    marko_node9 = marko_createElement("h2", null, "15", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Kakor vi använder"),
    marko_node10 = marko_createElement("ul", null, "16", null, 1, 0, {
        i: marko_const_nextId()
      })
      .e("li", null, null, null, 2)
        .e("h3", null, null, null, 1)
          .t("Kakor för formulär")
        .e("p", null, null, null, 1)
          .t("När du gör en kostberäkning så fyller du i ett formulär som sedan skickas till Kostbevakningens beräkningstjänst. För att förenkla för dig som användare så använder vi kakor för att komma ihåg vad du fyllt i."),
    marko_node11 = marko_createElement("h2", null, "20", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Tredjepartskakor"),
    marko_node12 = marko_createElement("p", {
        "class": "flow-text"
      }, "21", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Vi använder oss också av kakor som sätts av andra tjänster som vi litar på."),
    marko_node13 = marko_createElement("ul", null, "22", null, 2, 0, {
        i: marko_const_nextId()
      })
      .e("li", null, null, null, 2)
        .e("p", {
            "class": "flow-text"
          }, null, null, 1)
          .t("Kostbevakningen använder sig av Google Analytics för att hålla reda på vilka som använder tjänsten och hur.")
        .e("p", {
            "class": "flow-text"
          }, null, null, 1)
          .t("För information om hur Google Analytics använder kakor, gå till den officiella sidan för Google Analytics.")
      .e("li", null, null, null, 2)
        .e("p", {
            "class": "flow-text"
          }, null, null, 1)
          .t("Vi använder oss också av Facebooks sociala plattform för att hålla kontakt med våra användare. ")
        .e("p", {
            "class": "flow-text"
          }, null, null, 1)
          .t("För information om hur Facebook använder kakor, gå till den officiella sidan för Facebook."),
    marko_node14 = marko_createElement("h2", null, "29", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Mer information"),
    marko_node15 = marko_createElement("p", {
        "class": "flow-text"
      }, "30", null, 2, 0, {
        i: marko_const_nextId()
      })
      .t("Förhoppningsvis har du fått svar på dina frågor kring kakor, men det går självfallet alltid bra att höra av sig till oss på: ")
      .e("a", {
          href: "mailto:support@kostbevakningen.se"
        }, null, null, 1)
        .t("support@kostbevakningen.se"),
    marko_node16 = marko_createElement("h2", null, "32", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Nöttoppar"),
    marko_node17 = marko_createElement("p", {
        "class": "flow-text"
      }, "33", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Nöttoppen är en populär kaka som bakas helt utan mjöl, vilket gör den till en glutenfri goding som passar många."),
    marko_node18 = marko_createElement("p", {
        "class": "flow-text"
      }, "34", null, 2, 0, {
        i: marko_const_nextId()
      })
      .t("Källa: ")
      .e("a", {
          href: "https://www.ica.se/recept/nottoppar-722076/"
        }, null, null, 1)
        .t("ICA.se"),
    marko_node19 = marko_createElement("h3", null, "36", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Ingredienser"),
    marko_node20 = marko_createElement("p", {
        "class": "flow-text"
      }, "37", null, 8, 0, {
        i: marko_const_nextId()
      })
      .t("1 1/2 dl hasselnötter")
      .e("br", null, null, null, 0)
      .t(" 50 g smör")
      .e("br", null, null, null, 0)
      .t(" 1 ägg")
      .e("br", null, null, null, 0)
      .t(" 1 dl strösocker")
      .e("br", null, null, null, 0),
    marko_node21 = marko_createElement("h3", null, "42", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Gör så här"),
    marko_node22 = marko_createElement("ol", null, "43", null, 4, 0, {
        i: marko_const_nextId()
      })
      .e("li", {
          "class": "flow-text"
        }, null, null, 1)
        .t("Sätt ugnen på 175°C.")
      .e("li", {
          "class": "flow-text"
        }, null, null, 1)
        .t("Mal nötterna i en mandelkvarn och smält smöret. Rör ihop ägg och socker. Blanda ner nötter och smör. ")
      .e("li", {
          "class": "flow-text"
        }, null, null, 1)
        .t("Klicka ut smeten på en plåt med bakplåtspapper, tryck ner en nöt i varje kaka.")
      .e("li", {
          "class": "flow-text"
        }, null, null, 1)
        .t("Garnering: Grädda mitt i ugnen ca 10 minuter. Låt svalna på galler.");

function render(input, out, __component, component, state) {
  var data = input;

  marko_dynamicTag(out, Surround, function() {
    return {
        content: {
            renderBody: function(out) {
              out.be("div", marko_attrs0, "2", component);

              out.be("div", marko_attrs1, "3", component);

              out.n(marko_node0, component);

              out.n(marko_node1, component);

              out.n(marko_node2, component);

              out.n(marko_node3, component);

              out.n(marko_node4, component);

              out.n(marko_node5, component);

              out.n(marko_node6, component);

              out.n(marko_node7, component);

              out.n(marko_node8, component);

              out.n(marko_node9, component);

              out.n(marko_node10, component);

              out.n(marko_node11, component);

              out.n(marko_node12, component);

              out.n(marko_node13, component);

              out.n(marko_node14, component);

              out.n(marko_node15, component);

              out.n(marko_node16, component);

              out.n(marko_node17, component);

              out.n(marko_node18, component);

              out.n(marko_node19, component);

              out.n(marko_node20, component);

              out.n(marko_node21, component);

              out.n(marko_node22, component);

              out.be("p", null, "48", component);

              kb_link_tag({
                  href: "/?foodinput=1%201%2F2%20dl%20hasselnötter%0A50%20g%20smör%0A1%20ägg%0A1%20dl%20strösocker",
                  class: "btn-large",
                  renderBody: function(out) {
                    out.t("Se näringsinnehåll");
                  }
                }, out, __component, "49");

              out.ee();

              out.ee();

              out.ee();
            }
          }
      };
  }, null, null, null, __component, "0");
}

marko_template._ = marko_renderer(render, {
    ___implicit: true,
    ___type: marko_componentType
  });

marko_template.Component = marko_defineComponent({}, marko_template._);
