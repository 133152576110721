const { getEntries: getEntriesFromApi, calculateRows, textToRows } = require('Services/food-api.js')
const logger = require('@kb-front/common/logger')
const _ = require('lodash-es').default
const moment = require('moment')
const fields = ['carbs', 'fibres', 'protein', 'fat', 'alcohol']
const { newEntry } = require('Services/diary.js')
const { maxNutrient } = require('Services/nutrient-calc.js')

const sumNutrs = (x, y, unit) => {
  const result = {}
  fields.map(field => {
    result[field] = x[unit][field] + y[unit][field]
  })
  return result
}

// https://gist.github.com/colingourlay/82506396503c05e2bb94
const sortByKeys = (object, order = 'asc') => {
  const keys = Object.keys(object)
  const sortedKeys = _.orderBy(keys, order)
  return _.fromPairs(_.map(sortedKeys, key => [key, object[key]]))
}

// TODO: entry.result could possibly be {} or undefined in cases where there hadn't been a proper save.
const ensureResultOfEntry = entry =>
  new Promise(resolve => {
    if (entry.result) {
      resolve(entry)
    } else {
      // No totals from backend, recalculate and save
      calculateRows(textToRows(entry.input)).then(result => {
        entry.result = result
        resolve(entry)
        // save as side-effect but don't need to await this
        // saveEntry({
        //   id: entry.id,
        //   result: result,
        // })
      })
    }
  })

const ensureIdOfDbEntry = dbEntry =>
  new Promise(resolve => {
    if (dbEntry.id) resolve(dbEntry)
    else {
      dbEntry.id = dbEntry._id
      resolve(dbEntry)
    }
  })

const totals = entries => {
  return entries.reduce(
    (acc, entry) => {
      const entryTotals = entry.result.totals
      return {
        grams: sumNutrs(acc, entry.result.totals, 'grams'),
        kJ: sumNutrs(acc, entry.result.totals, 'kJ'),
        kcalTotal: acc.kcalTotal + entryTotals.kcal.total,
      }
    },
    {
      grams: _.fromPairs(fields.map(f => [f, 0])),
      kJ: _.fromPairs(fields.map(f => [f, 0])),
      kcalTotal: 0,
    }
  )
}

const getEntries = () =>
  getEntriesFromApi()
    .then(dbEntries =>
      Promise.all(
        dbEntries.map(async dbEntry => {
          await ensureIdOfDbEntry(dbEntry)
          await ensureResultOfEntry(dbEntry.entry)
          return dbEntry
        })
      )
    )
    .then(dbEntries =>
      _.chain(dbEntries)
        .map(dbEntry => Object.assign(dbEntry.entry, { id: dbEntry.id, timestamp: moment(dbEntry.entry.timestamp) }))
        .groupBy(e =>
          moment(e.timestamp)
            .startOf('day')
            .format('YYYY-MM-DD')
        )
    )
    // Add today if not present
    // .then(savedEntries => {
    //   const today = moment().format('YYYY-MM-DD')
    //   if (!savedEntries.includes(today)) {
    //     return savedEntries.merge({ [today]: [] })
    //   }
    //   return savedEntries
    // })
    .then(e => e.mapValues(entries => ({ entries, totals: totals(entries) })))

module.exports = {
  onInput() {
    this.state = {
      entries: [],
      newEntries: [],
      maxKjOfAnyNutr: 0,
    }
    this.refreshEntries()
  },

  refreshEntries() {
    getEntries().then(entries => {
      this.state.entries = sortByKeys(entries.value(), 'desc')
      this.state.maxKjOfAnyNutr = entries
        .values()
        .map(e => maxNutrient(e.totals))
        .max()
        .value()
    })
  },

  savedEntry(entry) {
    logger.debug('saved entry', entry)
    this.refreshEntries()
  },

  deletedEntry(entry) {
    logger.debug('deleted entry', entry)
    this.refreshEntries()
  },

  toggle(entryId) {
    this.getComponent(entryId).toggle()
  },

  add(date) {
    this.state.newEntries[date] = newEntry()
    this.state.newEntries[date].timestamp = moment(date).startOf('day')
    this.setStateDirty('entries')
    this.getComponent(date).toggle()
  },

  abort(date) {
    this.getComponent(date)
      .toggle()
      .then(() => {
        this.state.newEntries[date] = undefined
        this.setStateDirty('entries')
      })
  },
}
