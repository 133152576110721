// import {getAllUsers} from '../../../services/users' // TODO: kill those relative paths with some kind of resolve?

const { getAllUsers } = require('Services/users')

module.exports = {
  onInput() {
    this.state = {
      users: undefined,
    }
    getAllUsers().then(users => (this.state.users = users))
  },
}
