const session = require('Services/session')
const { location, document } = require('window')
const fbLoginModalView = require('../../../fb-login-modal')
const logger = require('@kb-front/common/logger').default

module.exports = {
  onCreate() {
    this.state = {
      user: undefined,
    }
    session.onSessionEvent(session => {
      this.setState('user', session.user)
    })

    fbLoginModalView.render({}).then(result => {
      result.appendTo(document.body)
      this.fbLoginModal = result.getComponent()
    })
  },

  logout() {
    session.logout()
    this.hasRenderedUserMenu = false
  },

  showLogin() {
    return location.hostname.startsWith('beta') || location.hostname.startsWith('localhost')
  },

  openFbLoginModal() {
    logger.debug('open mutha')
    this.fbLoginModal.emit('open')
  },
}
