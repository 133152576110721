// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/components/kb-header/components/header-links/components/nested-menu-mobile.marko", function() {
      return module.exports;
    }),
    marko_component = require("./nested-menu-mobile.component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_dynamicTag = marko_helpers.d,
    marko_attrs0 = {
        "class": "collapsible"
      },
    marko_attrs1 = {
        style: "padding-left: 32px",
        "class": "collapsible-header waves-effect waves-teal"
      },
    marko_attrs2 = {
        "class": "collapsible-body"
      };

function render(input, out, __component, component, state) {
  var data = input;

  out.be("li", null, "0", component);

  out.be("ul", marko_attrs0, "@collapsible", component);

  out.be("li", null, "1", component);

  out.be("a", marko_attrs1, "2", component);

  out.t(input.link);

  out.t(" ");

  marko_dynamicTag(out, input.linkContent, null, null, null, null, __component, "3");

  out.ee();

  out.be("div", marko_attrs2, "4", component);

  out.be("ul", null, "5", component);

  marko_dynamicTag(out, input.renderBody, null, null, null, null, __component, "6");

  out.ee();

  out.ee();

  out.ee();

  out.ee();

  out.ee();
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
