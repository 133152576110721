const { searchGraph } = require('Services/food-api')
const { logger } = require('@kb-front/common')
module.exports = {
  onCreate() {
    this.state = {
      searchTerm: '',
      ingredients: [],
    }
    searchGraph().then(is => (this.state.ingredients = is))
  },
  submit(event) {
    const value = event.target.value
    this.state.searchTerm = value
    logger.debug(value)
    searchGraph(value).then(result => (this.state.ingredients = result))
    event.preventDefault()
  },
}
