// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/components/kb-link/index.marko", function() {
      return module.exports;
    }),
    marko_component = require("./component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_dynamicTag = marko_helpers.d,
    marko_attrs = marko_helpers.as;

function render(input, out, __component, component, state) {
  var data = input;

  const attrs = Object.assign({}, input);

  delete attrs.renderBody;

  delete attrs.activeClass;

  delete attrs.parentElement;

  delete attrs.parentClass;

  out.be("a", marko_attrs(attrs), "@a", component, null, 0, {
      onclick: __component.d("click", "navigate", false)
    });

  if (input.renderBody) {
    marko_dynamicTag(out, input.renderBody, null, null, null, null, __component, "0");
  }

  out.ee();
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
