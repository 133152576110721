// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    marko_component = {
        onMount: function() {}
      },
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/components/onboarding.marko", function() {
      return module.exports;
    }),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    kb_link_template = require("./kb-link/index.marko"),
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_loadTag = marko_helpers.t,
    kb_link_tag = marko_loadTag(kb_link_template),
    marko_createElement = marko_helpers.e,
    marko_const = marko_helpers.const,
    marko_const_nextId = marko_const("27f4ec"),
    marko_node0 = marko_createElement("h1", null, "0", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Räkna på mat?"),
    marko_node1 = marko_createElement("p", {
        "class": "reader-friendly ingress"
      }, "1", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Oavsett om du vill räkna kalorier, kolla kolhydraterna eller se hur mycket fibrer du får i dig så kan Kostbevakningen hjälpa dig. Fyll i ingredienserna som om det vore ett recept och få reda på energiinnehållet och hur fördelningen mellan proteiner, fett och kolhydrater ser ut."),
    marko_attrs0 = {
        "class": "reader-friendly small-text"
      };

function render(input, out, __component, component, state) {
  var data = input;

  out.n(marko_node0, component);

  out.n(marko_node1, component);

  out.be("p", null, "2", component);

  kb_link_tag({
      href: "/help",
      parentClass: true,
      renderBody: function(out) {
        out.t("Se exempel på hur man kan söka");
      }
    }, out, __component, "3");

  out.ee();

  out.be("p", marko_attrs0, "4", component);

  out.t("Genom att fortsätta använda Kostbevakningen så accepterar du vår ");

  kb_link_tag({
      href: "/cookies",
      parentClass: true,
      renderBody: function(out) {
        out.t("Kakpolicy");
      }
    }, out, __component, "5");

  out.ee();
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
