const { calculateRows, textToRows, saveEntry, deleteEntry } = require('Services/food-api')
const { datepickerOptions, timepickerOptions } = require('MaterializeWrappers/datepicker-utils')
const M = require('M')
const moment = require('moment')
const logger = require('@kb-front/common/logger')

module.exports = {
  onInput(input) {
    this.state = {
      title: input.diary.title,
      dateString: input.diary.timestamp.format('YYYY-MM-DD'),
      timeString: input.diary.timestamp.format('HH:mm'),
      input: input.diary.input,
      result: input.diary.result,
    }
    M.Datepicker.init(this.getEl('date-input'), datepickerOptions)
    M.Timepicker.init(this.getEl('time-input'), timepickerOptions)
  },

  onUpdate() {},

  updateField(field, event) {
    this.setState(field, event.target.value)
  },

  recalc(text) {
    this.state.input = text
    calculateRows(textToRows(text)).then(result => {
      this.state.result = result
    })
  },

  // TODO: Maybe extract this somewhere since it might be a pattern for event sourcing?
  updateFromInputValue(rec, change, field, key) {
    const val = this.getEl(key).value
    if (val !== rec[field]) {
      change[field] = val
    }
  },

  // TODO: eventhandling instead?
  save() {
    const existingEntry = this.input.diary

    const change = {
      id: existingEntry.id,
    }

    if (existingEntry.input !== this.state.input) {
      change.input = this.state.input
      change.result = this.state.result
    }

    const m = moment(`${this.getEl('date-input').value}T${this.getEl('time-input').value}`)
    if (m !== existingEntry.timestamp) {
      change.timestamp = m.format()
    }

    this.updateFromInputValue(existingEntry, change, 'title', 'title')

    // TODO: make sure there text and result!

    if (existingEntry.input || change.input)
      saveEntry(change).then(entry => {
        this.emit('saved-entry', entry)
      })
    else {
      logger.debug('no input')
    }
  },

  // How could I save:

  // {
  //   "_id" : ObjectId("5d778e0488565e4ba4ea7667"),
  //   "id" : ObjectId("5d778e0488565e4ba4ea7666"),
  //   "userId" : ObjectId("5d10c342a2e32a1b3c45b4c2"),
  //   "entry" : {
  //     "timestamp" : "2019-09-10T13:50:00+02:00",
  //     "title" : "Fika"
  //   }
  // }

  delete() {
    deleteEntry(this.input.diary.id).then(() => {
      this.emit('deleted-entry', this.input.diary)
    })
  },

  abort() {
    this.emit('abort')
  },
}
