// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_classList = marko_helpers.cl,
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/components/food-output/index.marko", function() {
      return module.exports;
    }),
    marko_component = require("./component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    module_common_module = require("@kb-front/common"),
    common_module = module_common_module.default || module_common_module,
    formatting = module_common_module.formatting,
    marko_forEach = marko_helpers.f,
    nutrient_bars_template = require("../nutrient-bars.marko"),
    marko_loadTag = marko_helpers.t,
    nutrient_bars_tag = marko_loadTag(nutrient_bars_template),
    row_output_template = require("./components/row-output.marko"),
    row_output_tag = marko_loadTag(row_output_template),
    marko_classAttr = marko_helpers.ca,
    marko_attrs0 = {
        "class": "table table-nutrients striped"
      },
    marko_createElement = marko_helpers.e,
    marko_const = marko_helpers.const,
    marko_const_nextId = marko_const("7c7383"),
    marko_node0 = marko_createElement("th", null, "3", null, 0, 0, {
        i: marko_const_nextId()
      }),
    marko_node1 = marko_createElement("th", null, "4", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Ingrediens"),
    marko_node2 = marko_createElement("th", {
        "class": "right-align"
      }, "5", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Energi"),
    marko_node3 = marko_createElement("th", {
        "class": "right-align"
      }, "6", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Protein"),
    marko_node4 = marko_createElement("th", {
        "class": "right-align"
      }, "7", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Fett"),
    marko_node5 = marko_createElement("th", {
        "class": "right-align"
      }, "8", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Kolhydr."),
    marko_attrs1 = {
        "class": "nutrients-total"
      },
    marko_node6 = marko_createElement("td", null, "39", null, 0, 0, {
        i: marko_const_nextId()
      }),
    marko_node7 = marko_createElement("td", null, "40", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Totalt"),
    marko_attrs2 = {
        "class": "right-align"
      },
    marko_node8 = marko_createElement("td", null, "44", null, 0, 0, {
        i: marko_const_nextId()
      }),
    marko_node9 = marko_createElement("td", {
        colspan: "2"
      }, "45", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("   i gram"),
    marko_node10 = marko_createElement("td", null, "48", null, 0, 0, {
        i: marko_const_nextId()
      }),
    marko_node11 = marko_createElement("td", {
        colspan: "2"
      }, "49", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("   i energiprocent"),
    marko_node12 = marko_createElement("th", {
        "class": "right-align"
      }, "9", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Fibrer"),
    marko_node13 = marko_createElement("th", {
        "class": "right-align"
      }, "10", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Alkohol"),
    marko_attrs3 = {
        "class": "success"
      },
    marko_attrs4 = {
        "class": "nutrient-bars"
      },
    marko_attrs5 = {
        "class": "right-align"
      },
    marko_attrs6 = {
        "class": "right-align"
      },
    marko_attrs7 = {
        "class": "right-align"
      },
    marko_attrs8 = {
        "class": "right-align"
      },
    marko_attrs9 = {
        "class": "right-align"
      },
    marko_attrs10 = {
        "class": "right-align"
      },
    marko_attrs11 = {
        "class": "missing-ingredient"
      },
    marko_attrs12 = {
        "class": "missing-density"
      },
    marko_attrs13 = {
        "class": "missing-unit"
      },
    marko_attrs14 = {
        "class": "failure"
      },
    marko_attrs15 = {
        "class": "table table-nutrients striped"
      },
    marko_node14 = marko_createElement("thead", null, "52", null, 1, 0, {
        i: marko_const_nextId()
      })
      .e("tr", null, null, null, 2)
        .e("th", null, null, null, 1)
          .t("Ingrediens")
        .e("th", null, null, null, 0),
    marko_attrs16 = {
        "class": "nutrients-total"
      },
    marko_node15 = marko_createElement("td", null, "90", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Totalt"),
    marko_attrs17 = {
        "class": "right-align"
      },
    marko_attrs18 = {
        colspan: "2",
        "class": "pt-0"
      },
    marko_node16 = marko_createElement("div", {
        "class": "mobile-macronutrient-header"
      }, "94", null, 4, 0, {
        i: marko_const_nextId()
      })
      .e("div", {
          "class": "col s3 zero-padding"
        }, null, null, 1)
        .t("Protein")
      .e("div", {
          "class": "col s3 zero-padding"
        }, null, null, 1)
        .t("Fett")
      .e("div", {
          "class": "col s3 zero-padding"
        }, null, null, 1)
        .t("Kolh.")
      .e("div", {
          "class": "col s3 zero-padding"
        }, null, null, 1)
        .t(" "),
    marko_attrs19 = {
        "class": "mobile-macronutrient-data"
      },
    marko_attrs20 = {
        "class": "col s3 zero-padding"
      },
    marko_attrs21 = {
        "class": "col s3 zero-padding"
      },
    marko_attrs22 = {
        "class": "col s3 zero-padding"
      },
    marko_node17 = marko_createElement("div", {
        "class": "col s3 zero-padding"
      }, "103", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t(" "),
    marko_attrs23 = {
        "class": "mobile-macronutrient-data"
      },
    marko_attrs24 = {
        "class": "col s3 zero-padding"
      },
    marko_attrs25 = {
        "class": "col s3 zero-padding"
      },
    marko_attrs26 = {
        "class": "col s3 zero-padding"
      },
    marko_node18 = marko_createElement("div", {
        "class": "col s3 zero-padding"
      }, "108", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t(" "),
    marko_attrs27 = {
        "class": "nutrient-bars right-align"
      },
    marko_attrs28 = {
        colspan: "2",
        "class": "pt-0"
      },
    marko_attrs29 = {
        "class": "mobile-macronutrient-header"
      },
    marko_attrs30 = {
        "class": "mobile-macronutrient-data"
      },
    marko_attrs31 = {
        "class": "col s3 zero-padding"
      },
    marko_attrs32 = {
        "class": "col s3 zero-padding"
      },
    marko_attrs33 = {
        "class": "col s3 zero-padding"
      },
    marko_attrs34 = {
        "class": "col s3 zero-padding"
      },
    marko_attrs35 = {
        "class": "col s3 zero-padding"
      },
    marko_attrs36 = {
        "class": "col s3 zero-padding"
      },
    marko_attrs37 = {
        "class": "col s3 zero-padding"
      },
    marko_attrs38 = {
        "class": "col s3 zero-padding"
      },
    marko_attrs39 = {
        "class": "missing-ingredient"
      },
    marko_attrs40 = {
        "class": "missing-ingredient"
      },
    marko_attrs41 = {
        colspan: "2"
      },
    marko_attrs42 = {
        colspan: "2"
      },
    marko_attrs43 = {
        "class": "missing-density"
      },
    marko_attrs44 = {
        "class": "missing-density"
      },
    marko_attrs45 = {
        colspan: "2"
      },
    marko_attrs46 = {
        colspan: "2"
      },
    marko_attrs47 = {
        "class": "missing-unit"
      },
    marko_attrs48 = {
        "class": "missing-unit"
      },
    marko_attrs49 = {
        colspan: "2"
      },
    marko_attrs50 = {
        colspan: "2"
      },
    marko_attrs51 = {
        "class": "failure"
      },
    marko_attrs52 = {
        colspan: "2"
      };

function render(input, out, __component, component, state) {
  var data = input;

  const {formatGrams, formatKcal, formatPercent} = formatting

  if ((input.response && input.response.rows) && input.response.rows.length) {
    const desktop = state.width >= 640

    const mobile = state.width < 500

    if (!mobile) {
      out.be("table", marko_attrs0, "0", component);

      out.be("thead", null, "1", component);

      out.be("tr", null, "2", component);

      out.n(marko_node0, component);

      out.n(marko_node1, component);

      out.n(marko_node2, component);

      out.n(marko_node3, component);

      out.n(marko_node4, component);

      out.n(marko_node5, component);

      if (desktop) {
        out.n(marko_node12, component);

        out.n(marko_node13, component);
      }

      out.ee();

      out.ee();

      out.be("tbody", null, "11", component);

      var $for$0 = 0;

      marko_forEach(input.response.rows, function(row) {
        var $keyScope$0 = "[" + (($for$0++) + "]");

        if (row.result === "success") {
          out.be("tr", marko_attrs3, "12" + $keyScope$0, component);

          out.be("td", marko_attrs4, "13" + $keyScope$0, component);

          nutrient_bars_tag({
              currentRow: row,
              allRows: input.response.rows
            }, out, __component, "14" + $keyScope$0);

          out.ee();

          out.e("td", null, "15" + $keyScope$0, component, 1)
            .t(row.ingredientText);

          out.be("td", marko_attrs5, "16" + $keyScope$0, component);

          out.h(formatKcal(row.kcal.total));

          out.ee();

          out.be("td", marko_attrs6, "17" + $keyScope$0, component);

          out.h(formatGrams(row.grams.protein));

          out.ee();

          out.be("td", marko_attrs7, "18" + $keyScope$0, component);

          out.h(formatGrams(row.grams.fat));

          out.ee();

          out.be("td", marko_attrs8, "19" + $keyScope$0, component);

          out.h(formatGrams(row.grams.carbs));

          out.ee();

          if (desktop) {
            out.be("td", marko_attrs9, "20" + $keyScope$0, component);

            out.h(formatGrams(row.grams.fibres));

            out.ee();

            out.be("td", marko_attrs10, "21" + $keyScope$0, component);

            out.h(formatGrams(row.grams.alcohol));

            out.ee();
          }

          out.ee();
        } else if (row.result === "missingIngredient") {
          out.e("tr", marko_attrs11, "22" + $keyScope$0, component, 3)
            .e("td", null, "23" + $keyScope$0, component, 0)
            .e("td", null, "24" + $keyScope$0, component, 1)
              .t(row.ingredientText)
            .e("td", {
                colspan: desktop ? 6 : 4
              }, "25" + $keyScope$0, component, 1)
              .t("Ingrediensen saknas i vår databas");
        } else if (row.result === "missingDensity") {
          out.e("tr", marko_attrs12, "26" + $keyScope$0, component, 3)
            .e("td", null, "27" + $keyScope$0, component, 0)
            .e("td", null, "28" + $keyScope$0, component, 1)
              .t(row.ingredientText)
            .e("td", {
                colspan: desktop ? 6 : 4
              }, "29" + $keyScope$0, component, 1)
              .t("Densitet saknas på ingrediensen i vår databas");
        } else if (row.result === "missingUnit") {
          out.e("tr", marko_attrs13, "30" + $keyScope$0, component, 3)
            .e("td", null, "31" + $keyScope$0, component, 0)
            .e("td", null, "32" + $keyScope$0, component, 1)
              .t(row.ingredientText)
            .e("td", {
                colspan: desktop ? 6 : 4
              }, "33" + $keyScope$0, component, 1)
              .t("Enheten saknas i vår databas för denna ingrediens");
        } else {
          out.e("tr", marko_attrs14, "34" + $keyScope$0, component, 1)
            .e("td", {
                colspan: desktop ? 8 : 6
              }, "35" + $keyScope$0, component, 1)
              .e("i", null, "36" + $keyScope$0, component, 1)
                .t(row.ingredientText);
        }
      });

      out.ee();

      out.be("tfoot", null, "37", component);

      out.be("tr", marko_attrs1, "38", component);

      out.n(marko_node6, component);

      out.n(marko_node7, component);

      out.be("td", marko_attrs2, "41", component);

      out.h(formatKcal(input.response.totals.kcal.total));

      out.ee();

      row_output_tag({
          full: desktop,
          format: formatKcal,
          totals: input.response.totals.kcal
        }, out, __component, "42");

      out.ee();

      out.be("tr", null, "43", component);

      out.n(marko_node8, component);

      out.n(marko_node9, component);

      row_output_tag({
          full: desktop,
          format: formatGrams,
          totals: input.response.totals.grams
        }, out, __component, "46");

      out.ee();

      out.be("tr", null, "47", component);

      out.n(marko_node10, component);

      out.n(marko_node11, component);

      row_output_tag({
          full: desktop,
          format: formatPercent,
          totals: input.response.totals.percent
        }, out, __component, "50");

      out.ee();

      out.ee();

      out.ee();
    } else {
      out.be("table", marko_attrs15, "51", component);

      out.n(marko_node14, component);

      out.be("tbody", null, "56", component);

      var $for$1 = 0;

      marko_forEach(input.response.rows, function(row, index) {
        var $keyScope$1 = "[" + (($for$1++) + "]");

        if (row.result === "success") {
          const striped = index % 2 === 0

          out.be("tr", {
              "class": marko_classAttr(marko_classList([
                "success",
                {
                    "striped-row": striped,
                    "non-striped-row": !striped
                  }
              ]))
            }, "57" + $keyScope$1, component, null, 1);

          out.e("td", null, "58" + $keyScope$1, component, 1)
            .t(row.ingredientText);

          out.be("td", marko_attrs27, "59" + $keyScope$1, component);

          nutrient_bars_tag({
              currentRow: row,
              allRows: input.response.rows
            }, out, __component, "60" + $keyScope$1);

          out.ee();

          out.ee();

          out.be("tr", {
              "class": marko_classAttr({
                  "striped-row": striped,
                  "non-striped-row": !striped
                })
            }, "61" + $keyScope$1, component, null, 1);

          out.be("td", marko_attrs28, "62" + $keyScope$1, component);

          out.e("div", marko_attrs29, "63" + $keyScope$1, component, 4)
            .e("div", marko_attrs35, "64" + $keyScope$1, component, 1)
              .t("Protein")
            .e("div", marko_attrs36, "65" + $keyScope$1, component, 1)
              .t("Fett")
            .e("div", marko_attrs37, "66" + $keyScope$1, component, 1)
              .t("Kolh.")
            .e("div", marko_attrs38, "67" + $keyScope$1, component, 1)
              .t("Energi");

          out.be("div", marko_attrs30, "68" + $keyScope$1, component);

          out.be("div", marko_attrs31, "69" + $keyScope$1, component);

          out.h(formatGrams(row.grams.protein));

          out.ee();

          out.be("div", marko_attrs32, "70" + $keyScope$1, component);

          out.h(formatGrams(row.grams.fat));

          out.ee();

          out.be("div", marko_attrs33, "71" + $keyScope$1, component);

          out.h(formatGrams(row.grams.carbs));

          out.ee();

          out.be("div", marko_attrs34, "72" + $keyScope$1, component);

          out.h(formatKcal(row.kcal.total));

          out.ee();

          out.ee();

          out.ee();

          out.ee();
        } else if (row.result === "missingIngredient") {
          out.e("tr", marko_attrs39, "73" + $keyScope$1, component, 1)
            .e("td", marko_attrs41, "74" + $keyScope$1, component, 1)
              .t(row.ingredientText);

          out.e("tr", marko_attrs40, "75" + $keyScope$1, component, 1)
            .e("td", marko_attrs42, "76" + $keyScope$1, component, 1)
              .t("Ingrediensen saknas i vår databas");
        } else if (row.result === "missingDensity") {
          out.e("tr", marko_attrs43, "77" + $keyScope$1, component, 1)
            .e("td", marko_attrs45, "78" + $keyScope$1, component, 1)
              .t(row.ingredientText);

          out.e("tr", marko_attrs44, "79" + $keyScope$1, component, 1)
            .e("td", marko_attrs46, "80" + $keyScope$1, component, 1)
              .t("Densitet saknas på ingrediensen i vår databas");
        } else if (row.result === "missingUnit") {
          out.e("tr", marko_attrs47, "81" + $keyScope$1, component, 1)
            .e("td", marko_attrs49, "82" + $keyScope$1, component, 1)
              .t(row.ingredientText);

          out.e("tr", marko_attrs48, "83" + $keyScope$1, component, 1)
            .e("td", marko_attrs50, "84" + $keyScope$1, component, 1)
              .t("Enheten saknas i vår databas för denna ingrediens");
        } else {
          out.e("tr", marko_attrs51, "85" + $keyScope$1, component, 1)
            .e("td", marko_attrs52, "86" + $keyScope$1, component, 1)
              .e("i", null, "87" + $keyScope$1, component, 1)
                .t(row.ingredientText);
        }
      });

      out.ee();

      out.be("tfoot", null, "88", component);

      out.be("tr", marko_attrs16, "89", component);

      out.n(marko_node15, component);

      out.be("td", marko_attrs17, "91", component);

      out.h(formatKcal(input.response.totals.kcal.total));

      out.ee();

      out.ee();

      out.be("tr", null, "92", component);

      out.be("td", marko_attrs18, "93", component);

      out.n(marko_node16, component);

      out.be("div", marko_attrs19, "99", component);

      out.be("div", marko_attrs20, "100", component);

      out.h(formatGrams(input.response.totals.grams.protein));

      out.ee();

      out.be("div", marko_attrs21, "101", component);

      out.h(formatGrams(input.response.totals.grams.fat));

      out.ee();

      out.be("div", marko_attrs22, "102", component);

      out.h(formatGrams(input.response.totals.grams.carbs));

      out.ee();

      out.n(marko_node17, component);

      out.ee();

      out.be("div", marko_attrs23, "104", component);

      out.be("div", marko_attrs24, "105", component);

      out.h(formatPercent(input.response.totals.percent.protein));

      out.ee();

      out.be("div", marko_attrs25, "106", component);

      out.h(formatPercent(input.response.totals.percent.fat));

      out.ee();

      out.be("div", marko_attrs26, "107", component);

      out.h(formatPercent(input.response.totals.percent.carbs));

      out.ee();

      out.n(marko_node18, component);

      out.ee();

      out.ee();

      out.ee();

      out.ee();

      out.ee();
    }
  } else {}
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
