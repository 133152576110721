// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/pages/diary/diary.marko", function() {
      return module.exports;
    }),
    marko_component = require("./diary.component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    module_Surround = require("MarkoSurround"),
    Surround = module_Surround.default || module_Surround,
    add_meal_top_template = require("./components/add-meal-top.marko"),
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_loadTag = marko_helpers.t,
    add_meal_top_tag = marko_loadTag(add_meal_top_template),
    diary_table_template = require("./components/diary-table.marko"),
    diary_table_tag = marko_loadTag(diary_table_template),
    marko_dynamicTag = marko_helpers.d,
    marko_createElement = marko_helpers.e,
    marko_const = marko_helpers.const,
    marko_const_nextId = marko_const("024cc1"),
    marko_node0 = marko_createElement("div", {
        "class": "row"
      }, "2", null, 1, 0, {
        i: marko_const_nextId()
      })
      .e("div", {
          "class": "col s12"
        }, null, null, 1)
        .e("h1", null, null, null, 1)
          .t("Dagbok"),
    marko_attrs0 = {
        "class": "row"
      },
    marko_attrs1 = {
        "class": "col s12"
      },
    marko_attrs2 = {
        "class": "row diary"
      },
    marko_attrs3 = {
        "class": "col s12"
      };

function render(input, out, __component, component, state) {
  var data = input;

  marko_dynamicTag(out, Surround, function() {
    return {
        content: {
            renderBody: function(out) {
              out.n(marko_node0, component);

              out.be("div", marko_attrs0, "5", component);

              out.be("div", marko_attrs1, "6", component);

              add_meal_top_tag({}, out, __component, "7");

              out.ee();

              out.ee();

              out.be("div", marko_attrs2, "8", component);

              out.be("div", marko_attrs3, "9", component);

              diary_table_tag({}, out, __component, "diary-table");

              out.ee();

              out.ee();
            }
          }
      };
  }, null, null, null, __component, "0");
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
