// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/components/kb-footer.marko", function() {
      return module.exports;
    }),
    marko_component = require("./kb-footer.component"),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    marko_attrs0 = {
        "class": "page-footer"
      },
    marko_attrs1 = {
        "class": "container"
      },
    marko_attrs2 = {
        "class": "center"
      },
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_createElement = marko_helpers.e,
    marko_const = marko_helpers.const,
    marko_const_nextId = marko_const("8eae21"),
    marko_node0 = marko_createElement("a", {
        "class": "",
        href: "https://twitter.com/#!/hedefalk"
      }, "3", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Hedefalk"),
    marko_node1 = marko_createElement("a", {
        "class": "",
        href: "http://about.me/jimmy.flink"
      }, "4", null, 1, 0, {
        i: marko_const_nextId()
      })
      .t("Flink");

function render(input, out, __component, component, state) {
  var data = input;

  out.e("footer", marko_attrs0, "0", component, 1)
    .e("div", marko_attrs1, "1", component, 1)
      .e("p", marko_attrs2, "2", component, 6)
        .t("© 2012- ")
        .t(Math.max(2018, (new Date()).getFullYear()))
        .t("   ")
        .n(marko_node0, component)
        .t(" & ")
        .n(marko_node1, component);
}

marko_template._ = marko_renderer(render, {
    ___type: marko_componentType
  }, marko_component);

marko_template.Component = marko_defineComponent(marko_component, marko_template._);
