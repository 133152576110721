import polyfill from '@babel/polyfill' // eslint-disable-line
import window from 'window'
import mainComponent from './index.marko'
import * as moment from 'moment'

const darkMode = window.location.hostname.startsWith('dark')
if (darkMode) window.document.body.className += 'dark-mode'

// Check if we were started from iOS home screen. If so – go dark!
if ('standalone' in window.navigator && window.navigator.standalone) {
  window.document.body.className += ' dark-mode'
}

moment.locale('sv')
mainComponent.renderSync().appendTo(window.document.body)
