// Compiled using marko@4.18.4 - DO NOT EDIT
"use strict";

var marko_template = module.exports = require("marko/src/vdom").t(),
    components_helpers = require("marko/src/runtime/components/helpers"),
    marko_registerComponent = components_helpers.rc,
    marko_componentType = marko_registerComponent("/@kb-front/web$18.10.5/src/marko/components/facebook-like-box.marko", function() {
      return module.exports;
    }),
    marko_renderer = components_helpers.r,
    marko_defineComponent = components_helpers.c,
    marko_helpers = require("marko/src/runtime/vdom/helpers"),
    marko_createElement = marko_helpers.e,
    marko_const = marko_helpers.const,
    marko_const_nextId = marko_const("c1ab0b"),
    marko_node0 = marko_createElement("div", {
        "class": "row facebook-row"
      }, "0", null, 1, 0, {
        i: marko_const_nextId()
      })
      .e("div", {
          "class": "col s12"
        }, null, null, 1)
        .e("div", {
            "class": "fb-page",
            "data-href": "https://www.facebook.com/kostbevakningen",
            "data-width": "360",
            "data-hide-cover": "false",
            "data-show-facepile": "true"
          }, null, null, 0);

function render(input, out, __component, component, state) {
  var data = input;

  out.n(marko_node0, component);
}

marko_template._ = marko_renderer(render, {
    ___implicit: true,
    ___type: marko_componentType
  });

marko_template.Component = marko_defineComponent({}, marko_template._);
